import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function bootstrapApp(config) {
  if (config.production) {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

fetch('/configuration/config.json')
  .then((response) => response.json())
  .then((config) => {
    bootstrapApp(config);
  })
  .catch((err) => {
    console.error('Configuration fetch failed:', err);
    // Fallback to default environment config
    bootstrapApp(environment);
  })

