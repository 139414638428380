<div class="aggregates-page" [ngClass]="{'nocursor': isFullScreen && hideButtons}">
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div [style.width.vw]="100 * pageNumber" class="carousel" [ngStyle]="{'left': (-100 * (selectedPage - 1)) + 'vw'}">
        <div class="content-holder" [style.width.%]="100 / pageNumber" *ngFor="let page of pageArray">
            <div class="box-container" [style.height.%]="50" [style.width.vw]="100" *ngIf="connected">
                <div *ngFor="let device of devices | filterArray: [gridNumber * (page - 1), (gridNumber/2) + (gridNumber * (page - 1))]" 
                [style.width.%]="100">
                    <device-box #deviceBox
                        [style.width.%]="100"
                        [device]="device"
                        [mqttService]="_mqttService">
                    </device-box>
                </div>
            </div>
            <div class="box-container" [style.height.%]="50" [style.width.vw]="100" *ngIf="connected">
                <div *ngFor="let device of devices | filterArray: [(gridNumber/2) + (gridNumber * (page - 1)), gridNumber * page]" 
                [style.width.%]="100 / (gridNumber/2)">
                    <device-box #deviceBox
                        [style.width.%]="100"
                        [device]="device"
                        [mqttService]="_mqttService">
                    </device-box>
                </div>
            </div>
        </div>
    </div>
    <div class="navigation-button prev" *ngIf="!hideButtons && selectedPage > 1" (click)="prevPage()">
        <i class="fa fa-angle-left"></i>
    </div>
    <div class="navigation-button next" *ngIf="!hideButtons && selectedPage < pageNumber" (click)="nextPage()">
        <i class="fa fa-angle-right"></i>
    </div>
    <div class="navigation-button top" *ngIf="!hideButtons && !showDisconnected" (click)="getData(true)">
        {{'aggregates.show' | translate}}
    </div>
    <div class="navigation-button top" *ngIf="!hideButtons && showDisconnected" (click)="getData(false)">
        {{'aggregates.hide' | translate}}
    </div>
    <div class="navigation-button bottom" *ngIf="!hideButtons && !isFullScreen" (click)="openFullScreen()">
        <i class="fa fa-expand"></i>
    </div>
    <div class="navigation-button bottom" *ngIf="!hideButtons && isFullScreen" (click)="closeFullScreen()">
        <i class="fa fa-compress"></i>
    </div>
</div>
