<ng-template #stopType let-stop="stop" let-isParent="isParent">
    <div class="input-container">
        <mat-select 
          placeholder="{{ 'dialog_stop.stop_type' | translate }}"
          [ngModel]="stop.selectedStopType" 
          (selectionChange)="typeChanged($event, stop)" 
          floatPlaceholder="never">
            <mat-option [value]="null"> </mat-option>
            <mat-option [disabled]="!stopTypeEnabled(0, isParent)" [value]="0">{{'stops.unplanned' | translate}}</mat-option>
            <mat-option [disabled]="!stopTypeEnabled(1, isParent)" [value]="1">{{'stops.planned' | translate}}</mat-option>
            <mat-option [disabled]="!stopTypeEnabled(2, isParent)" [value]="2">{{'stops.change_over' | translate}}</mat-option>
            <mat-option [disabled]="!stopTypeEnabled(4, isParent)" [value]="4">{{'stops.not_production' | translate}}</mat-option>
            <mat-option [disabled]="!stopTypeEnabled(5, isParent)" [value]="5">{{'stops.not_specified' | translate}}</mat-option>
        </mat-select>
    </div>
</ng-template>
<ng-template #errorType let-stop="stop">
    <div class="input-container">
        <mat-select 
          placeholder="{{ 'dialog_stop.stop_reason' | translate }}"
          [ngModel]="stop.selectedErrorId" 
          (selectionChange)="stopReasonChanged($event, stop)" 
          floatPlaceholder="never"
          [disabled]="disableStopReason(stop)">
            <mat-option *ngFor="let error of errorsSelectList | stopErrorFilterPipe: [stop.selectedStopType]"
                [value]="error? error.id : null">{{error.text}}</mat-option>
        </mat-select>
    </div>
</ng-template>
<ng-template #editNote let-stop="stop">
    <input matInput placeholder="{{ 'dialog_stop.stop_note' | translate }}" [(ngModel)]="stop.note">
</ng-template>

<div class="dialog-container">
    <div class="dialog-header">
        <span>{{ 'dialog_stop.edit' | translate }}</span>
    </div>
    <div class="dialog-body" [ngClass]="splits && splits.length > 0 ? 'frozen-stop' : ''">
        <span class="detected-stop-title">{{ 'dialog_stop.detected_stop_title' | translate }}</span>
        <div class="detected-stop-date">
            <div class="stop-date-container">
                <span class="stop-date-title">{{ 'dialog_stop.start_date' | translate }}</span>
                <span class="stop-date-value">{{stop.beginAt | dateTimeFormat:'shortDate'}}</span>
            </div>
            <div class="stop-date-container">
                <span class="stop-date-title">{{ 'dialog_stop.end_date' | translate }}</span>
                <span *ngIf="stop.endAt" class="stop-date-value">{{stop.endAt | dateTimeFormat:'shortDate'}}</span>
                <span *ngIf="!stop.endAt" class="stop-date-value">{{ 'dialog_stop.ongoing' | translate }}</span>
            </div>
            <div class="stop-date-container">
                <span class="stop-date-title">{{ 'dialog_stop.start_time' | translate }}</span>
                <span class="stop-date-value">{{stop.beginAt | dateTimeFormat:'shortTime'}}</span>
            </div>
            <div class="stop-date-container">
                <span class="stop-date-title">{{ 'dialog_stop.end_time' | translate }}</span>
                <span *ngIf="stop.endAt" class="stop-date-value">{{stop.endAt | dateTimeFormat:'shortTime'}}</span>
                <span *ngIf="!stop.endAt" class="stop-date-value">{{ 'dialog_stop.ongoing' | translate }}</span>
            </div>
        </div>
        <div class="detected-stop-date">
            <div class="stop-date-container">
                <span class="stop-date-title">{{ 'dialog_stop.stop_type' | translate }}  <i aria-hidden="true">*</i></span>
                <span class="stop-date-value">
                    <div *ngIf="splits && splits.length > 0">
                        {{getStopTypeLabel(stop)}}
                    </div>
                    <ng-container *ngIf="!splits || splits.length <= 0">
                        <ng-container  *ngTemplateOutlet="stopType; context: {stop: stop, isParent: true }"></ng-container>
                    </ng-container>  
                </span>
            </div>
            <div class="stop-date-container">
                <span class="stop-date-title">{{ 'dialog_stop.stop_reason' | translate }} <i *ngIf="stopReasonRequired(stop)" aria-hidden="true">*</i></span>
                <span class="stop-date-value">
                    <div *ngIf="splits && splits.length > 0">
                        {{getStopCauseLabelByStopType(stop)}}
                    </div>
                    <ng-container *ngIf="!splits || splits.length <= 0">
                        <ng-container  *ngTemplateOutlet="errorType; context: {stop: stop }"></ng-container>
                    </ng-container>  
                </span>
            </div>
            <div class="stop-date-container">
                <span class="stop-date-title">{{ 'dialog_stop.stop_note' | translate }}</span>
                <span class="stop-date-value">
                    <div *ngIf="splits && splits.length > 0">
                        {{stop.note}}
                    </div>
                    <ng-container *ngIf="!splits || splits.length <= 0">
                        <ng-container  *ngTemplateOutlet="editNote; context: {stop: stop }"></ng-container>
                    </ng-container>  
                </span>
            </div>
        </div>
    </div>

    <!-- Splits from parent -->
    <div class="dialog-body">      
        <div *ngFor="let split of splits; index as i">
            <div>
                <span class="detected-stop-title">Split {{i+1}}</span>
                <span class="detected-stop-trash" (click)="removeSplit(split,i)"><i class="fa fa-trash"></i></span> 
            </div>
            
            <div class="detected-stop-date">
                <div class="stop-date-container">
                    <span class="stop-date-title">{{ 'dialog_stop.start_date' | translate }}</span>
                    <span class="stop-date-value" *ngIf="!beginAtEditable(i)">{{split.beginAt | dateTimeFormat:'shortDate'}}</span>
                    <mat-form-field *ngIf="beginAtEditable(i)" class="date-picker">
                        <input matInput 
                        [matDatepicker]="startDate" 
                        [value]="split.beginAt" 
                        class="date-picker-label" 
                        (dateChange)="checkBeginSplitDate($event, split, i)">
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker [startAt]="split.beginAt" #startDate></mat-datepicker>
                      </mat-form-field>
                    <!-- <span class="stop-date-value">{{split.beginAt | date:'dd/MM/yyyy'}}</span> -->
                </div>
                <div class="stop-date-container">
                    <span class="stop-date-title">{{ 'dialog_stop.end_date' | translate }}</span>
                    <span class="stop-date-value" *ngIf="!endAtEditable(i)">{{split.endAt | dateTimeFormat:'shortDate'}}</span>
                    <mat-form-field class="date-picker" *ngIf="endAtEditable(i)">
                        <input matInput 
                        [matDatepicker]="endDate" 
                        [(value)]="split.endAt" 
                        class="date-picker-label" 
                        (dateChange)="checkEndSplitDate($event, split, i)">
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker [startAt]="split.endAt" #endDate></mat-datepicker>
                      </mat-form-field>
                </div>
                <!-- anche la data inizio / fine sono editabili !!!!!!!!! -->
                <div class="stop-date-container">
                    <span class="stop-date-title">{{ 'dialog_stop.start_time' | translate }}</span>
                    <span class="stop-date-value" *ngIf="beginAtEditable(i)">
                        <ngx-timepicker-field matInput 
                            [controlOnly]="true" [format]="24" 
                            [defaultTime]="'00:00'" 
                            [ngModel]="split.beginAt | date:'shortTime'"
                            (ngModelChange)="setBeginSplitTime($event, split, i)"
                            #begintimepicker>
                        </ngx-timepicker-field>
                    </span>
                    <span class="stop-date-value" *ngIf="!beginAtEditable(i)">{{split.beginAt | dateTimeFormat:'shortTime'}}</span>      
                </div>
                <div class="stop-date-container">
                    <span class="stop-date-title">{{ 'dialog_stop.end_time' | translate }}</span>
                    <span class="stop-date-value" *ngIf="endAtEditable(i)">
                        <ngx-timepicker-field 
                            matInput 
                            [controlOnly]="true" [format]="24" 
                            [defaultTime]="'00:00'" 
                            [ngModel]="split.endAt | date:'shortTime'"
                            (ngModelChange)="setEndSplitTime($event, split, i)"
                            #endtimepicker>
                        </ngx-timepicker-field>
                    </span>
                    <span class="stop-date-value" *ngIf="!endAtEditable(i)">{{split.endAt | dateTimeFormat:'shortTime'}}</span>
                </div>
            </div>
            <div class="detected-stop-date">
                <div class="stop-date-container">
                    <span class="stop-date-title">{{ 'dialog_stop.stop_type' | translate }} <i aria-hidden="true">*</i></span>
                    <span class="stop-date-value">
                        <ng-container  *ngTemplateOutlet="stopType; context: {stop: split, isParent: false }"></ng-container>
                    </span>
                </div>
                <div class="stop-date-container">
                    <span class="stop-date-title">{{ 'dialog_stop.stop_reason' | translate }}  <i *ngIf="stopReasonRequired(split)" aria-hidden="true">*</i></span>
                    <span class="stop-date-value">
                        <ng-container  *ngTemplateOutlet="errorType; context: {stop: split }"></ng-container>
                    </span>
                </div>
                <div class="stop-date-container">
                    <span class="stop-date-title">{{ 'dialog_stop.stop_note' | translate }}</span>
                    <span class="stop-date-value">
                        <ng-container  *ngTemplateOutlet="editNote; context: {stop: split }"></ng-container>
                    </span>
                </div>
            </div>
        </div>

        <div class="detected-stop-date">
            <button *ngIf="stop.endAt" [disabled]="loading" (click)="addSplit()" class="gray-button">
                <span>{{ 'dialog_stop.add_split' | translate }}</span>
                <i class="fa fa-plus" style="float:right; margin-right:2px;"></i>
            </button>
        </div>
    </div>
    <div class="dialog-actions">
        <button (click)="cancel()" [disabled]="loading" class="gray-button">{{ 'dialog_stop.cancel' | translate }}</button>
        <button (click)="saveSplits()" [disabled]="!formValid || loading" class="color-button">{{ 'dialog_stop.ok' | translate }}</button>
        <!-- <button [disabled]="loading" (click)="ok()" class="color-button" *ngIf="errorId">{{ 'dialog_stop.ok' | translate }}</button>
        <button [disabled]="loading" (click)="changeType()" class="color-button" *ngIf="stop.type === 0 || stop.type === 2">{{ 'dialog_stop.ok' | translate }}</button>-->
    </div>


    
<!-- <mat-tab-group *ngIf="stop.type === 1 || stop.type === 3">
    <mat-tab label="{{ 'dialog_stop.planned' | translate }}">
        <mat-form-field>
            <mat-label>{{ 'dialog_stop.title' | translate }}</mat-label>
            <mat-select [(ngModel)]="errorId">
                <mat-optgroup *ngFor="let tag of plannedList" value="item" label="{{tag.name}}">
                    <mat-option *ngFor="let error of tag.Errors" value="{{error.id}}">
                        <span class="{{error.errorTypeString}}">{{error.text}}</span>
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </mat-tab>
    <mat-tab label="{{ 'dialog_stop.unplanned' | translate }}">
        <mat-form-field>
            <mat-label>{{ 'dialog_stop.title' | translate }}</mat-label>
            <mat-select [(ngModel)]="errorId" >
                <mat-optgroup *ngFor="let tag of unplannedList" value="item" label="{{tag.name}}">
                    <mat-option *ngFor="let error of tag.Errors" value="{{error.id}}">
                        <span class="{{error.errorTypeString}}">{{error.text}}</span>
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </mat-tab>
</mat-tab-group>

<div *ngIf="stop.type === 0 || stop.type === 2">
    <h3>{{ 'stops.error_type' | translate }}</h3>
    <mat-form-field>
        <mat-select [(ngModel)]="stop.type" >
            <mat-option [value]="0">
                <span>{{'stops.change_over' | translate}}</span>
            </mat-option>
            <mat-option [value]="2">
                <span>{{'stops.not_production' | translate}}</span>
            </mat-option>
        </mat-select>
  </mat-form-field>
</div> -->


