// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    apiBase: 'https://backendv2.develop.techmass.io/apiv1',
    excelExportBase: 'https://backendv2.develop.techmass.io/excelExport',
    mqttHost: 'websocket.develop.techmass.io',
    mqttPort: 80,
    mqttProtocol: 'ws',
    mqttPath: null,
    RTfetchDataInterval: 60000,
    httpTimeoutMs: 60000,
    smartTvUrl: 'http://smarttv.develop.techmass.io/realtime'
};
