import { Product } from './product';
import { Device } from './device';
import { Order } from './order';
import { User } from './user';
export class Session {
  id: number;
  productId?: number;
  deviceId: number;
  itemsTotal: number;
  scraps: number;
  beginAt: Date;
  createdAt: Date;
  endAt: Date;
  updatedAt: Date;
  lastData: Date;
  active: number;
  totalTime: number;
  itemsPerMinute: number;
  scrapsPercentage: number;
  Device: Device;
  Product: Product;
  isLast?: boolean;
  orderObj?: Order[];
  User?: User;
  itemsDeclared?: number;
  scrapsDeclared?: number;
  note?: string;
  orderId?:number;
  groupId?: number;
  targetSpeed?: number;
};


