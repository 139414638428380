<div class="device-box" [ngClass]="{
    'ok': (device.state !== 0 && oee && oee.oee >= 67) || (device.state === 1 && !device.Session),
    'warning': device.state !== 0 && oee && oee.oee < 67 && oee.oee >= 33,
    'error': (device.state !== 0 && oee && oee.oee < 33)
}" (click)="returnToGraph()">
    <div class="content-container" *ngIf="device.state !== 0">
        <span class="line-name">{{device.label}}</span>
        <img src="{{getLoaderSrc()}}" *ngIf="!oee && device.Session" height="200" class="wait-oee">
        <span *ngIf="oee && oee.oee && device.Session && device.state !== 1" class="oee">{{oee.oee.toFixed(1)}}%</span>
        <span *ngIf="device.state == 1 && !device.Session" class="info-label">{{'stops.change_over' | translate}}</span>
        <div *ngIf="oee && device.state === 1 && device.Session" class="error-label">
            <span *ngIf="!device.Error">{{'realtime.stop' | translate}}</span>
            <span *ngIf="device.Error" style="word-break: break-all;">{{device.Error.text}}</span><br/>
            <span>{{timer | timer}}</span>
        </div>
        <span class="code-label">{{'filter_bar.prod_code' | translate}}</span>
        <span *ngIf="device.Product" class="product">{{device.Product.code}}</span>
        <span *ngIf="!device.Product" class="product">{{'realtime.not_defined' | translate}}</span>
    </div>
    <div class="content-container" *ngIf="device.state === 0">
        <span class="line-name">{{device.label}}</span>
        <span class="info-label">{{'realtime.disconnected' | translate}}</span>
    </div>
</div>