<div class="qa-dashboard">
    <app-filter-bar></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder">
        <div class="container-donuts">
            <div class="donuts">
                <span class="donuts-title">{{'qa-product.product-non-compliance' | translate}}</span>
                <div class="compliaceDonutChart" id="compliaceDonutChart"></div>
            </div>
            <div class="donuts">
                <span class="donuts-title">{{'qa-product.qa-executed' | translate}}</span>

            </div>
        </div>
        <div class="content">
            <h2 class="title">
                {{'qa-dashboard.sub-title' | translate}}
            </h2>
            <div class="chart-section">

                <div class="container-filter">
                </div>
                <div class="container-circle">
                </div>
                <div class="container-filter chart-content">
                </div>
                <!-- <div class="chart" id="chart"></div> -->
            </div>
        </div>
    </div>
</div>