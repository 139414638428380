import * as moment from 'moment/moment';
import { Error } from 'tslint/lib/error';
import { Product } from '../models/product';
import { ProductService } from './product.service';
import { Subject } from 'rxjs/Rx';
import { DevicesService } from './devices.service';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '../models/device';
import { OrderDataService } from './order-data.service';
import {UserRole} from "../models/user";
import { Order } from 'app/models/order';
export interface DropdownItem {
    id: number;
    label: string;
}

export interface OrderDropdownItem {
    id: string;
    label: string;
}

export interface OrderCoreDropdownItem {
    id: number;
    label: string;
}

export type DateInterval = 'today' | 'yesterday' | 'lastweek' | 'week' | 'month' | 'lastmonth' | 'year' | 'schedules';

@Injectable()
export class ActiveFiltersService {
    private _deviceId: number;
    private devices: Device[];
    private _device: Device;
    private _dateBegin: Date;
    private _dateEnd: Date;
    public _productId = 0;
    public _orderCoreId = 0;
    public _controlId = 0;
    public _orderId = "";
    private _granularity = 0;
    private _timerMode = false;
    private _schedules: string;
    private _existShiftInRange: boolean = true;
    public showOrderList: boolean = false;
    private _deviceIds: number[];

    public productsList: DropdownItem[];
    public controlsList: DropdownItem[];
    public ordersList: OrderDropdownItem[];
    public orderCoreList: OrderCoreDropdownItem[];
    public activeDateInterval: DateInterval;
    public onFilterChanged = new EventEmitter<void>();
    public onDevicesListChanged = new EventEmitter<void>();
    public onDeviceFilterChanged: Subject<number> = new Subject<number>();
    public datesRangeSubject: Subject<string> = new Subject<string>();

    constructor(
        private _devicesService: DevicesService,
        private _productService: ProductService,
        private _translate: TranslateService,
        private _orderService: OrderDataService
    ) { }

    /**
     * Try to set an active device (the first) if available
     *
     *
     * @memberOf ActiveFiltersService
     */
    async initializeActiveDevice() {
        // TODO: Recheck this when there will be a devices page
        this.devices = await this._devicesService.getDevices();
        this.onDevicesListChanged.emit();        
        if (!this.deviceId) {
            if (this.devices.length) {
                for (const device of this.devices) {
                    if (!device.ProductionLine) {
                        this.deviceId = this.devices[0].id;
                        break;
                    }
                }
            } else {
                // TODO: discover why Error is not a constructor
                // throw new Error('no devices found');
                console.error('no devices found');
            }
        } else {
            this.deviceIds = [this.deviceId]
        }
        this._device = this.devices.filter((item) => {
            return item.id === this.deviceId;
        })[0];

        if (this._device) {
            this._timerMode = this._device.sessionType === 2 ? true : false;
        }
        this.emitChangeEvent(true);
    }

    /**
     * Emit an event change, to call when a filter is changed
     *
     *
     * @memberOf ActiveFiltersService
     */
    async emitChangeEvent(preventEmit?: boolean) {
        this.showOrderList = this.showOrderListFunc();
        this.writeInLocalStorage('deviceId', this._deviceId);
        this.writeInLocalStorage('productId', this._productId);
        this.writeInLocalStorage('orderCoreId', this._orderCoreId);
        this.writeInLocalStorage('granularity', this._granularity);
        this.writeInLocalStorage('dateBegin', moment(this._dateBegin).format());
        this.writeInLocalStorage('dateEnd', moment(this._dateEnd).format());
        this.writeInLocalStorage('schedules', this._schedules);

        if (!preventEmit) {
            this.onFilterChanged.emit();
            this._refetchProductsList();
            this._refetchOrdersCoreList();
        }
        if (this._device && this._device.ProductionLine) {
            this._refetchOrdersList();
        }
    }

    /**
     *
     * Set begin and end date from an interval string (i.e. 'today')
     *
     * @param {DateInterval} intervalString
     *
     * @memberOf ActiveFiltersService
     */
    setDateFromIntervalString(intervalString: DateInterval) {
        [this.dateBegin, this.dateEnd] = this.computeDatesFromIntervalString(intervalString);
        this.writeInLocalStorage('dateBegin', moment(this._dateBegin).format());
        this.writeInLocalStorage('dateEnd', moment(this._dateEnd).format());
        this.activeDateInterval = intervalString;

        this.datesRangeSubject.next(this.activeDateInterval);
    }

    /**
     *
     * Set begin and end date
     *
     * @param {Date} startDate
     * @param {Date} endDate
     *
     * @memberOf ActiveFiltersService
     */
    setDates(startDate: Date, endDate: Date) {
        this.dateBegin = startDate;
        this.dateEnd = endDate;
        this.writeInLocalStorage('dateBegin', moment(this._dateBegin).format());
        this.writeInLocalStorage('dateEnd', moment(this._dateEnd).format());
    }

    /**
     * Compute begin and end date from an interval string (i.e. 'today')
     *
     * @param {DateInterval} intervalString
     * @returns {Date}
     *
     * @memberOf ActiveFiltersService
     */
    computeDatesFromIntervalString(intervalString: DateInterval): Date[] {
        let begin = moment().toDate();
        let end = moment().toDate();
        begin.setHours(0);
        begin.setMinutes(0);
        begin.setSeconds(0);

        let dayOfWeek: number;
        switch (intervalString) {
            case 'today':
                break;
            case 'yesterday':
                begin.setDate(begin.getDate() - 1);
                end.setDate(end.getDate() - 1);
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);
                break;
            case 'lastweek':
                // js dates starts from sunday, shift the day in order to begin from monday
                dayOfWeek = begin.getDay() - 1;
                if (dayOfWeek < 0) {
                    dayOfWeek = 6;
                }

                // set the date to monday
                begin.setDate(begin.getDate() - dayOfWeek);
                begin.setHours(0);
                begin.setMinutes(0);
                begin.setSeconds(0);
                end = new Date(begin);
                end.setDate(end.getDate() - 1);
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);
                begin.setDate(begin.getDate() - 7);
                break;
            // case 'week':
            //     // js dates starts from sunday, shift the day in order to begin from monday
            //     let day = begin.getDay() - 1;
            //     if (day < 0) {
            //         day = 6;
            //     }

            //     // set the date to monday
            //     begin.setDate(begin.getDate() - day);
            //     break;
            case 'lastmonth':
                begin.setDate(-1);
                begin.setDate(1);
                end.setDate(1);
                end.setDate(end.getDate() - 1);
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);
                break;
            case 'month':
                begin.setDate(1);
                begin.setHours(0);
                begin.setMinutes(0);
                begin.setSeconds(0);
                break;
            case 'year':
                begin.setMonth(0);
                begin.setDate(1);
                begin.setHours(0);
                begin.setMinutes(0);
                begin.setSeconds(0);
                break;
            case 'schedules':
                begin = end;
                break;
            default:
                throw new Error('wrong date interval');
        }

        return [begin, end];
    }

    get schedules() {
        return this._schedules;
    }

    set schedules(schedules: string) {
        this._schedules = schedules;
    }

    get granularity() {
        return this._granularity;
    }
    set granularity(granularity: number) {
        this._granularity = granularity;
    }
    get deviceId() {
        return parseInt('' + this._deviceId, 10);
    }
    get timerMode() {
        return this._timerMode;
    }
    set deviceId(id: number) {
        this._deviceId = id;
        this._productId = 0;
        this._orderCoreId = 0;
        this._orderId = '';
        // this._satelliteUrl = this.devices.filter((item) => {
        //     return item.id === this.deviceId
        // })[0].Company.satelliteUrl;

        this._deviceIds = [this._deviceId];
        // reload products list
        this._refetchProductsList();
        // reload order list
        this._refetchOrdersList();
        this._refetchOrdersCoreList();
        this.onDeviceFilterChanged.next(id);
    }

    get device() {
        if (this.devices) {
            return this._device = this.devices.filter((item) => {
                return item.id === this.deviceId;
            })[0];
        }
    }

    get productId() {
        return this._productId;
    }

    set productId(productId: any) {
        if (typeof productId === 'string') {
            this._productId = parseInt(productId, 10);
        } else if (typeof productId === 'number') {
            this._productId = productId;
        }
    }

    get orderCoreId() {
        return this._orderCoreId;
    }

    set orderCoreId(orderCoreId: any) {
        if (typeof orderCoreId === 'string') {
            this._orderCoreId = parseInt(orderCoreId, 10);
        } else if (typeof orderCoreId === 'number') {
            this._orderCoreId = orderCoreId;
        }
    }

    get orderId() {
        return this._orderId;
    }

    set orderId(orderId: any) {
        if (typeof orderId === 'string') {
            this._orderId = orderId;
        } else if (typeof orderId === 'number') {
            this._orderId = orderId.toString();
        }
    }

    get controlId() {
        return this._controlId;
    }
    set controlId(controlId: any) {

        if (typeof controlId === 'string') {
            this._controlId = parseInt(controlId, 10);
        } else {
            this._controlId = controlId;
        }
    }

    set dateBegin(date: Date) {
        this._dateBegin = date;
        this.activeDateInterval = null;
    }
    get dateBegin() {
        return this._dateBegin;
    }
    set dateEnd(date: Date) {
        this._dateEnd = date;
        this.activeDateInterval = null;
    }
    get dateEnd() {
        return this._dateEnd;
    }

    set existShiftInRange(exist: boolean) {
        this._existShiftInRange = exist;
    }

    get existShiftInRange() {
        return this._existShiftInRange;
    }

    /**
     * Reload list of products from server and build the dropdown list
     *
     * @returns {Promise<any>}
     *
     * @memberOf ActiveFiltersService
     */
    async _refetchProductsList(): Promise<any> {
        if (this.deviceId) {
            let products: Product[];
            if (this._dateBegin && this._dateEnd) {
                products = await this._productService.getProducts(this.deviceId, this.dateBegin.toISOString(), this.dateEnd.toISOString());
            } else if (localStorage.getItem('dateBegin') && localStorage.getItem('dateEnd')) {
                this.dateBegin = localStorage.getItem('dateBegin') ? moment(localStorage.getItem('dateBegin')).toDate() : null;
                this.dateEnd = localStorage.getItem('dateEnd') ? moment(localStorage.getItem('dateEnd')).toDate() : null;
                products = await this._productService.getProducts(this.deviceId, this.dateBegin.toISOString(), this.dateEnd.toISOString());
            } else {
                products = await this._productService.getProducts(this.deviceId);
            }
            this._rebuildProductsList(products);
        }
    }

    /**
     * Reload list of order core from server and build the dropdown list
     * 
     * @returns {Promise<any>}
     *
     * @memberOf ActiveFiltersService
     */
    async _refetchOrdersCoreList():Promise<any> {
        try {
            this.showOrderList = this.showOrderListFunc();
            if (this.showOrderList) {
                let orders: Order[];
                if (this.deviceId) {
                    orders = await this._orderService.getOrderCoreList(this._dateBegin.toISOString(), this._dateEnd.toISOString(), this.deviceId);
                }
                this._rebuildOrdersCoreList(orders);
            }
        } catch(err) {
            console.error(err);
        }
    }

    formatDate(date: Date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds();
            
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-') + ' ' +  [hours, minutes, seconds].join(':');
    }

    /**
    * Reload list of order from server and build the dropdown list
    *
    * @returns {Promise<any>}
    *
    * @memberOf ActiveFiltersService
    */
    async _refetchOrdersList(): Promise<any> {

        let orders;

        if (this._device && this._device.ProductionLine && this._device.Company.satelliteUrl) {

            orders = await this._orderService.getOrderList(this._dateBegin, this._dateEnd, this._device.ProductionLine.id, this._device.Company.satelliteUrl);
            this._rebuildOrdersList(orders);

        } else if (this.devices && this.devices.length) {

            const device = this.devices.filter((item) => {
                return item.id === this.deviceId;
            })[0];

            if (device && device.ProductionLine && device.Company.satelliteUrl) {

                orders = await this._orderService.getOrderList(this._dateBegin, this._dateEnd, device.ProductionLine.id, device.Company.satelliteUrl);
                this._rebuildOrdersList(orders);

            }

        } else if (this.deviceId) {

            const devices = await this._devicesService.getDevices();
            const device = devices.filter((item) => {
                return item.id === this.deviceId;
            })[0];

            if (device && device.ProductionLine && device.Company.satelliteUrl) {

                orders = await this._orderService.getOrderList(this._dateBegin, this._dateEnd, device.ProductionLine.id, device.Company.satelliteUrl);
                this._rebuildOrdersList(orders);

            }
        }

    }

    /**
     * Rebuild products list with the default ('all products') plus the products given
     *
     * @private
     * @param {Product[]} [products]
     *
     * @memberOf ActiveFiltersService
     */
    private async _rebuildProductsList(products?: Product[]) {
        let label = 'ALL PRODUCTS';
        await this._translate.get('filter_bar.all_products').subscribe((res: string) => {
            label = res;
        });
        const productsList: DropdownItem[] = [{
            id: 0,
            label: label
        }];

        products.sort((a, b) => {
            if (!a || !b) return 0;
            var nameA = a.code ? a.code.toUpperCase() : 'N/D'; // ignore upper and lowercase
            var nameB = b.code ? b.code.toUpperCase() : 'N/D'; // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0; // names must be equal
        });

        if (products && products.length > 0) {

            products.forEach(product => {
                if (product && product.id) {
                    productsList.push({
                        id: product.id,
                        label: product.code ? product.code + ' - ' + product.name : 'N/D'
                    });
                }
            });
        }

        this.productsList = productsList;
    }


    /**
     * Rebuild products list with the default ('all products') plus the products given
     *
     * @private
     * @param {Product[]} [products]
     *
     * @memberOf ActiveFiltersService
     */
    private async _rebuildOrdersList(orders?: any[]) {
        let label = 'ALL ORDERS';
        await this._translate.get('filter_bar.all_orders').subscribe((res: string) => {
            label = res;
        });
        const ordersList: OrderDropdownItem[] = [{
            id: '',
            label: label
        }];

        orders.sort((a, b) => {
            var nameA = a.code ? a.code.toUpperCase() : 'N/D'; // ignore upper and lowercase
            var nameB = b.code ? b.code.toUpperCase() : 'N/D'; // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0; // names must be equal
        });

        if (orders) {

            orders.forEach(order => {
                ordersList.push({
                    id: order.code ? order.code : '000000',
                    label: order.code ? order.code : 'N/D'
                });
            });
        }

        this.ordersList = ordersList;
    }

    /**
     * 
     * @param ordersCore 
     */
    showOrderListFunc(): boolean {
        try {
            if (this.deviceId && this.device && this.device.Modules) {
                return (!!this.device.Modules.orders && !this.device.Modules.OrderModuleConfig.isOnSatellite)
            }
            return false;
        } catch(err) {
            console.error(err)
            return false;
        }
    }

        /**
     * Rebuild products list with the default ('all products') plus the products given
     *
     * @private
     * @param {Product[]} [products]
     *
     * @memberOf ActiveFiltersService
     */
    private async _rebuildOrdersCoreList(ordersCore?: any[]) {
        let label = 'ALL ORDERS';
        await this._translate.get('filter_bar.all_orders').subscribe((res: string) => {
            label = res;
        });
        const ordersCoreList: OrderCoreDropdownItem[] = [{
            id: 0,
            label: label
        }];

        ordersCore.sort((a, b) => {
            var nameA = a.code ? a.code.toUpperCase() : 'N/D'; // ignore upper and lowercase
            var nameB = b.code ? b.code.toUpperCase() : 'N/D'; // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0; // names must be equal
        });

        if (ordersCore) {

            ordersCore.forEach(order => {
                ordersCoreList.push({
                    id: order.id,
                    label: order.code ? order.code : 'N/D'
                });
            });
        }

        this.orderCoreList = ordersCoreList;
    }

    /**
     * Reload list of controls from server and build the dropdown list
     *
     * @returns {Promise<any>}
     *
     * @memberOf ActiveFiltersService
     */
    // async _refetchControlsList(): Promise<any> {
    //     if (this.deviceId) {
    //         const controls = await this._productService.getProducts(this.deviceId); // TO-DO service to get conbtrol
    //         this._rebuildControlsList(controls);
    //     }
    // }

    /**
     * Rebuild control list with the default ('all products') plus the products given
     *
     * @private
     * @param {Control[]} [control]
     *
     * @memberOf ActiveFiltersService
     */
    // private async _rebuildControlsList(controls?: Control[]) {
    //     let label = 'ALL CONTROLS';
    //     await this._translate.get('filter_bar.all_controls').subscribe((res: string) => {
    //         label = res;
    //     });
    //     const controlsList: DropdownItem[] = [{
    //         id: 0,
    //         label: label
    //     }];

    //     controls.sort((a, b) => {
    //         var nameA = a.description.toUpperCase(); // ignore upper and lowercase
    //         var nameB = b.description.toUpperCase(); // ignore upper and lowercase

    //         if (nameA < nameB) return -1;
    //         if (nameA > nameB) return 1;

    //         return 0; // names must be equal
    //     });

    //     if (controls) {

    //         controls.forEach(product => {
    //             controlsList.push({
    //                 id: product.id,
    //                 label: product.description
    //             });
    //         });
    //     }

    //     this.controlsList = controlsList;
    // }

    /**
     * Write data in local storage.
     *
     * @param {string} key
     * @param {any} data
     *
     * @memberof ActiveFiltersService
     */
    writeInLocalStorage(key: string, data: any) {
        localStorage.setItem(key, data);
        return;
    }

    /**
     * Read data from local storage
     *
     * @param {string} key
     *
     * @memberof ActiveFiltersService
     */
    readFromLocalStorage(key: string) {
        return localStorage.getItem(key);
    }

    resetToToday() {
        let today = moment();
        let todayStart = today.clone().hour(0).minute(0).second(0);
        this.setDates(todayStart.toDate(), today.toDate());    
    }

    set deviceIds(ids: number[]) {
        this._deviceIds = ids;
    }

    get deviceIds() {
        return this._deviceIds;
    }

    public isMultipleDeviceFilterEnabled(): boolean {
        let userRole = this.readFromLocalStorage("role");
        //Attivo solo per Admin e utenti Sit Group
        let isAdmin = userRole === "" + UserRole.admin;
        return  isAdmin;
    }

    public isMultipleDeviceSelected(): boolean {
        return this._deviceIds ? this._deviceIds.length >= 2 : false;
    }
}
