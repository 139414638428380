import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import { AmChartsService } from '@amcharts/amcharts3-angular';
import { Session } from '../../../models/session';
import { Device } from '../../../models/device';
import * as moment from 'moment/moment';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { OrderDataService } from 'app/services/order-data.service';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';
import { Order, OrderAnalytics, OrderChartData, OrderChartDataType, OrderCore, OrderDevice, OrderSession } from 'app/models/order';
import { Router } from '@angular/router';

am4core.useTheme(am4themes_animated);

const colors = {
    production: '#E5856D',
    notSpecified: '#6495ED',
    unplanned: '#D7510F',
    planned: '#AACC66',
    changeActivity: '#008048'
};

@Component({
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss']
})

export class OrderDetailComponent extends PageWithLoader implements OnInit, OnDestroy {

    // to lock data request if is already processing
    private _filterSubscription;
    lockRequest = true;
    devicesLine: Device[];
    private formattedData: OrderChartData[];
    order: OrderCore;
    analytics: OrderAnalytics[];
    statsAvaiable = false;
    private chart: any;
    statusSelected = 'all';
    constructor(
        public activeFilters: ActiveFiltersService,
        private _orderDataService: OrderDataService,
        private _AmCharts: AmChartsService,
        private _navbar: NavbarService,
        private _translate: TranslateService,
        private _router: Router,
        _themeService: ThemeService
    ) {
        super(_themeService);
        am4core.options.commercialLicense = true;
    }

    async ngOnInit() {
        this._translate.get('orders.title').subscribe((res: string) => {
            this._navbar.setTitle(res);
        });

        this._translate.onLangChange.subscribe(() => {
            this._navbar.setTitle(this._translate.instant('orders.title'));
        });

        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();

        if (!this.activeFilters.orderCoreId) {
            this._router.navigateByUrl('orders');
        } else {
            this.lockRequest = true;
            this.getDataAndDrawChart();
        }
    }


    ngOnDestroy() {
        if (this._AmCharts && this.chart) {
            this.chart.dispose();
        }
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    onFilterChanged() {
        if (!this.activeFilters.orderCoreId) {
            this._router.navigateByUrl('orders');
        } else {
            this.lockRequest = true;
            this.getDataAndDrawChart();
        }
    }

    /**
     * Function to get data based on the selected filter and draw the chart.
     *
     * @memberof RealtimeLineComponent
     */
    async getDataAndDrawChart() {
        this.statsAvaiable = false;
        
        this.order = await this._orderDataService.getOrderDataById(this.activeFilters.orderCoreId, this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString());

        [this.formattedData, this.analytics] = await this._formatOrdersForChart(this.order);

        this.lockRequest = false;
        this._navbar.setTitle(this.order.code);

        this.drawLineGanttChart(this.formattedData);
    }

    /**
     * Format an array of raw data to the format used by the chart
     *
     * @private
     * @param {RawData[]} rawData
     * @param {addEdgePoints} boolean add 0 data at the boundary of the datas, mandatory for amcharts to set a fixed time range
     * @returns {*}
     *
     * @memberof RealtimeLineComponent
     */
    private async _formatOrdersForChart(order: OrderCore): Promise<[OrderChartData[], OrderAnalytics[]]> {
        let chartData: OrderChartData[] = [];

        for (const device of order.Devices) {
            let sessions: OrderChartData[] = await this._formatOrderData(device.Sessions, device);
            chartData = chartData.concat(sessions);
        }

        chartData.sort((a, b) => a.beginAt.getTime() > b.beginAt.getTime() ? 1 : -1);

        let devicesAnalytics: OrderAnalytics[] = [];
        for (const data of chartData) {
            let duration = 0;
            if (data.endAt && data.beginAt) {
                duration = data.endAt.getTime() - data.beginAt.getTime();
            }
            const deviceIndex = devicesAnalytics.findIndex(d => d.id === data.id);
            if (deviceIndex < 0) {
                devicesAnalytics.push({
                    id: data.id,
                    label: data.label,
                    items: data.items,
                    target: order.target,
                    scraps: data.scraps,
                    totalTime: data.type === OrderChartDataType.session ? duration : 0,
                    stopTime: data.type === OrderChartDataType.stop ? duration : 0
                });
            } else {
                if (data.type === OrderChartDataType.session) {
                    devicesAnalytics[deviceIndex].totalTime += duration;
                } else {
                    devicesAnalytics[deviceIndex].stopTime += duration;
                }
            }
        }

        return [chartData, devicesAnalytics];
    }

    private async _formatOrderData(sessions: OrderSession[], device: OrderDevice): Promise<OrderChartData[]> {
        let chartData: OrderChartData[] = [];
        if (!sessions || !sessions.length) {
            chartData.push({
                id: device.id,
                stroke: colors.production,
                fill: colors.production,
                label: device.label,
                items: device.items,
                scraps: device.scraps,
                beginAt: new Date(this.activeFilters.dateEnd),
                type: OrderChartDataType.session
            });
        }
        for (let session of sessions) {
            let item: OrderChartData = {
                id: device.id,
                stroke: colors.production,
                fill: colors.production,
                label: device.label,
                items: device.items,
                scraps: device.scraps,
                beginAt: new Date(session.beginAt),
                type: OrderChartDataType.session
            };
    
            if (!session.endAt) {
                item.endAt = new Date(this.activeFilters.dateEnd);
            } else {
                item.endAt = new Date(session.endAt);
            }
            chartData.push(item);

            let unplanned = Object.assign({}, item);
            unplanned.beginAt = this._calculateStopsPercentageDate(session.unplanned, item.endAt, item.beginAt, item.endAt);
            unplanned.stroke = colors.unplanned;
            unplanned.fill = colors.unplanned;
            unplanned.type = OrderChartDataType.stop;

            chartData.push(unplanned);

            let planned = Object.assign({}, item);
            planned.endAt = new Date(unplanned.beginAt);
            planned.beginAt = this._calculateStopsPercentageDate(session.planned, unplanned.beginAt, item.beginAt, item.endAt);
            planned.stroke = colors.planned;
            planned.fill = colors.planned;
            planned.type = OrderChartDataType.stop;

            chartData.push(planned);

            let notSpecified = Object.assign({}, item);
            notSpecified.endAt = new Date(planned.beginAt);
            notSpecified.beginAt = this._calculateStopsPercentageDate(session.notSpecified, planned.beginAt, item.beginAt, item.endAt);
            notSpecified.stroke = colors.notSpecified;
            notSpecified.fill = colors.notSpecified;
            notSpecified.type = OrderChartDataType.stop;

            chartData.push(notSpecified);

            /*let changeWorkProcessTask = Object.assign({}, item);
            changeWorkProcessTask.endAt = new Date(notSpecified.beginAt);
            changeWorkProcessTask.beginAt = this._calculateStopsPercentageDate(session.changeWorkProcessTask, notSpecified.beginAt, item.beginAt, item.endAt);
            changeWorkProcessTask.stroke = colors.changeActivity;
            changeWorkProcessTask.fill = colors.changeActivity;
            changeWorkProcessTask.type = OrderChartDataType.stop;

            chartData.push(changeWorkProcessTask);*/

        }

        return chartData;
    }

    _calculateStopsPercentageDate(stopDuration: number, stopEndDate: Date, sessionBeginAt: Date, sessionEndAt: Date) {
        if (sessionBeginAt.getTime() < this.activeFilters.dateBegin.getTime()) {
            stopDuration = stopDuration * (sessionEndAt.getTime() - this.activeFilters.dateBegin.getTime()) /
                            (sessionEndAt.getTime() - sessionBeginAt.getTime());
        }
        return new Date(stopEndDate.getTime() - stopDuration);
    }

    /**
     * Function to draw gantt chart
     * 
     * @param {any}
     * @memberof RealtimeLineComponent
     */
    async drawLineGanttChart(data: OrderChartData[]) {

        if (this._AmCharts && this.chart) {
            await this.chart.dispose();
        }

        // create chart
        this.chart = am4core.create("chart", am4charts.XYChart);

        // apply data
        this.chart.data = data;

        // create axes
        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "id";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.labels.template.disabled = true;
        categoryAxis.cursorTooltipEnabled = false;

        let categoryAxis2 = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis2.dataFields.category = "label";
        categoryAxis2.renderer.grid.template.location = 0;
        categoryAxis2.renderer.inversed = true;
        categoryAxis2.renderer.minGridDistance = 60;
        categoryAxis2.renderer.labels.template.disabled = true;


        let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        this.chart.dateFormatter.dateFormat = "dd MMM yyyy HH:mm:ss";
        dateAxis.dateFormatter.dateFormat = "dd MMM yyyy HH:mm:ss";
        dateAxis.baseInterval = { count: 1, timeUnit: "second" };
        dateAxis.max = new Date(this.activeFilters.dateEnd).getTime();
        dateAxis.min = new Date(this.activeFilters.dateBegin).getTime();
        dateAxis.strictMinMax = true;
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.tooltipDateFormat = "MMM dd HH:mm:ss";

        dateAxis.minPeriod = 'hh';
        dateAxis.parseDate = true;
        dateAxis.dateFormats.setKey('second', 'HH:mm:ss');
        dateAxis.dateFormats.setKey('minute', 'HH:mm');
        dateAxis.dateFormats.setKey('hour', 'HH:mm');
        dateAxis.dateFormats.setKey('day', 'MMM dd');
        dateAxis.dateFormats.setKey('week', '[bold]MMM dd[/]');
        dateAxis.dateFormats.setKey('month', '[bold]MMM YYYY[/]');
        dateAxis.dateFormats.setKey('year', '[bold]YYYY[/]');

        dateAxis.periodChangeDateFormats.setKey('second', '[bold]HH:mm[/]');
        dateAxis.periodChangeDateFormats.setKey('minute', '[bold]HH:mm[/]');
        dateAxis.periodChangeDateFormats.setKey('hour', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('day', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('week', '[bold]MMM dd[/]');
        dateAxis.periodChangeDateFormats.setKey('month', '[bold]MMM YYYY[/]');
        dateAxis.periodChangeDateFormats.setKey('year', '[bold]YYYY[/]');

        this.chart.dateFormatter.inputDateFormat = "dd MMM yyyy HH:mm";

        let series = this.chart.series.push(new am4charts.ColumnSeries());

        series.dataFields.openDateX = "beginAt";
        series.dataFields.dateX = "endAt";
        series.dataFields.categoryY = "id";
        series.columns.template.propertyFields.fill = "fill"; // get color from data
        series.columns.template.propertyFields.stroke = "stroke";

        this.chart.scrollbarX = new am4core.Scrollbar();
        this.chart.scrollbarX.animationDuration = 10000;


        /* Create a cursor */
        this.chart.cursor = new am4charts.XYCursor();

        let cellSize = 60;
        let devicesLength = this.order.Devices.length;
        this.chart.events.on("datavalidated", function(ev) {
            // Get objects of interest
            let chart = ev.target;
            let categoryAxis = chart.yAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            let adjustHeight = devicesLength * cellSize - categoryAxis.pixelHeight;

            // get current chart height
            let targetHeight = chart.pixelHeight + adjustHeight;

            // Set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + "px";
        });

    }

    calculatePercentage(stopTime, totalTime) {
        if (!totalTime) {
            return 0;
        }
        const percentage = (stopTime/totalTime) * 100;
        if (percentage > 100) {
            return 100;
        }
        if (percentage < 0) {
            return 0;
        }

        return percentage.toFixed(2);
    }

    async filterStatusChanged() {
        [this.formattedData, this.analytics] = await this._formatOrdersForChart(this.order);
        this.drawLineGanttChart(this.formattedData);
    }
}