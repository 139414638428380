<div class="pieces-page">
    <app-filter-bar></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder">
        <div class="content" id="chartContainer">
            <div class="loss-title-container" *ngIf="isShiftSelected()">
                <div class="loss-shifts-number">
                    <span class="shifts-number-text">{{'oee.shifts_selected' | translate}}</span><span class="shifts-number">{{nShifts}}</span>
                </div>
            </div>
            <div class="time-filter-container" *ngIf="isGranularityAvailable()">
                <div 
                    *ngFor="let granularity of granularities"
                    (click)="setGranularity(granularity)"
                    [ngClass]="{'active': activeGranularityMinutes === granularity.granularityMinutes, 'disabled': disabledGranularities.indexOf(granularity.granularityMinutes) !== -1}"
                    class="time-filter"
                >{{ 'granularity.' + granularity.label | translate }}</div>
            </div>
            <div class="chart-section values" *ngIf="graphType === 'pieces'">
                <!-- <h2>{{ 'pieces.pieces' | translate }}</h2> -->
                <div id="chart-values" [style.width.%]="100" [style.height.%]="100" [ngClass]="{'no-data': !availableData}"></div>
                <div class="no-chart" *ngIf="!lockRequest && !availableData">
                    <img src="{{getFavIconSrc()}}" width="120"/>
                    <p>{{'pieces.no_data1' | translate}} {{'pieces.pieces' | translate}} {{'pieces.no_data2' | translate}}</p>
                </div>
            </div>
            <div class="chart-section scraps" *ngIf="graphType === 'scraps'">
                <!-- <h2>{{ 'pieces.scraps' | translate }}</h2> -->
                <div id="chart-scraps" [style.width.%]="100" [style.height.%]="100" [ngClass]="{'no-data': !availableData}"></div>
                <div class="no-chart" *ngIf="!lockRequest && !availableData">
                    <img src="{{getFavIconSrc()}}" width="120"/>
                    <p>{{'pieces.no_data1' | translate}} {{'pieces.scraps' | translate}} {{'pieces.no_data2' | translate}}</p>
                </div>
            </div>
            <div [ngClass]="{'no-data': !availableData}"
                *ngIf="stats && ((stats.piecesTotal && graphType === 'pieces') || (stats.scrapsTotal && graphType === 'scraps'))" class="total">
                {{ (graphType === 'pieces' ? 'pieces.pieces' : 'pieces.scraps') | translate }}
                <span class="orange"> {{graphType === 'pieces' ? stats.piecesTotal : stats.scrapsTotal}}</span>
            </div>
            <div *ngIf="sessions && sessions.length && device && device.companyId === 42" class="table-container">
                <div class="sessions-table">
                    <ngx-datatable #table
                        class="fullscreen"
                        [columns]="columns"
                        [rows]="sessions"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [rowHeight]="60">
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #begin let-value="value" let-row="row">
    <span class="date">{{row.beginAt | dateTimeFormat:'shortDate'}}</span>
    <span class="time">{{row.beginAt | dateTimeFormat:'shortTime'}}</span>
</ng-template>
<ng-template #end let-value="value" let-row="row">
    <span *ngIf="row.endAt" class="date">{{row.endAt | dateTimeFormat:'shortDate'}}</span>
    <span *ngIf="row.endAt" class="time">{{row.endAt | dateTimeFormat:'shortTime'}}</span>
</ng-template>
<ng-template #duration let-value="value">
    <span>{{(value/60000) | shortDurationPipe}}</span>
</ng-template>


<ng-template #product let-value="value" let-row="row">
    <span *ngIf="!row.Product">{{ 'stops.not_available' | translate }}</span>
    <span *ngIf="row.Product" class="code">{{row.Product.code}}</span>
    <span *ngIf="row.Product" class="name">{{row.Product.name}}</span>
</ng-template>

<ng-template #headerPieces>
    <p style="text-align: center;margin: 0;">{{'pieces.pieces' | translate}}</p>
</ng-template>

<ng-template #headerScraps>
    <p style="text-align: center;margin: 0;">{{'pieces.scraps' | translate}}</p>
</ng-template>

<ng-template #pieces let-value="value" let-row="row">
    <span *ngIf="!row.itemsDeclared && row.itemsDeclared !== 0">{{value}}</span>
    <span *ngIf="row.itemsDeclared || row.itemsDeclared === 0">{{row.itemsDeclared}}</span>
</ng-template>

<ng-template #scraps let-value="value" let-row="row">
    <span *ngIf="!row.scrapsDeclared && row.scrapsDeclared !== 0">{{value}}</span>
    <span *ngIf="row.scrapsDeclared || row.scrapsDeclared === 0">{{row.scrapsDeclared}}</span>
</ng-template>
<ng-template #note let-value="value" let-row="row">
    <span *ngIf="row.note">{{row.note}}</span>
</ng-template>
<ng-template #action let-value="value" let-row="row" let-rowIndex="rowIndex">
    <div class="action-container" *ngIf="userRole !== 1"> <!--shown if not supervisor-->
        <div class="update-section" *ngIf="row.User">
            <span>Last update</span>
            <span>{{row.updatedAt | dateTimeFormat:'shortDate'}}</span>
            <span *ngIf="row.User.firstName">{{row.User.firstName}} {{row.User.lastName}}</span>
            <span *ngIf="!row.User.firstName">{{row.User.email}}</span>
        </div>
        <button mat-mini-fab class="action-button" (click)="setEditSessionModal(rowIndex, row)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>
