<div class="qa-dashboard">
    <app-filter-bar></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder" >
        <div class="content">
            <h2 class="title" *ngIf="hasData">
                {{'qa-dashboard.sub-title' | translate}}
            </h2>
            <div class="chart-section">

                <div class="container-filter" *ngIf="hasData">
                    <div class="tcms-select">
                        <label>{{ 'qa-dashboard.tasks-type' | translate }}</label>
                        <select [(ngModel)]="selectedFilter.taskType" (change)="onQAFilterChanged('task')">
                            <option *ngFor="let item of activeTaskList" [value]="item.id">{{item.description}}</option>
                        </select>
                    </div>
                    <div class="tcms-select">
                        <label>{{ 'qa-dashboard.controls-type' | translate }}</label>
                        <select [(ngModel)]="selectedFilter.controlType" (change)="onQAFilterChanged('control')">
                            <option *ngFor="let item of activeControlList" value="{{item.id}}">{{item.description}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="container-circle" *ngIf="hasData">
                    <div class="qa-circle">
                        <div class="first">
                            <span class="title">{{ 'qa-dashboard.non-compliance' | translate }}</span>
                            <span class="value">{{ notCompliantControl.percentage}}<span
                                    class="percentage">%</span></span>
                            <span class="title">{{ notCompliantControl.count }}</span>
                        </div>
                    </div>
                    <div class="qa-circle">
                        <div class="second">
                            <span class="title">{{ 'qa-dashboard.not-executed' | translate }}</span>
                            <span class="value">{{ notDoneControl.percentage }}<span class="percentage">%</span></span>
                            <span class="title">{{ notDoneControl.count}}</span>
                        </div>
                    </div>
                    <div class="qa-circle">
                        <div class="third">
                            <span class="title">{{ 'qa-dashboard.compliance' | translate }}</span>
                            <span class="value">{{ compliantControl.percentage }}<span
                                    class="percentage">%</span></span>
                            <span class="title">{{ compliantControl.count }}</span>
                        </div>
                    </div>
                </div>
                <div class="container-filter chart-content" *ngIf="hasData">
                    <div class="control-select">
                        <label>{{ 'qa-dashboard.sort' | translate }}</label>
                        <mat-select (selectionChange)="changeControlCompliance()"
                            [(ngModel)]="selectedFilter.controlCompliance">
                            <mat-option *ngFor="let item of controlComplianceType" [value]="item.id">
                                {{item.description}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="chart" id="chart" *ngIf="hasData"></div>
                <div class="no-chart" *ngIf="!hasData">
                    <span>
                        {{'qa-dashboard.no_data' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>