<div class="filter-bar-holder">
    <div class="content">
        <div class="left">
            <div class="aggregates" *ngIf="isRootPage()" (click)="openSmartTV()">
                <i class="fa fa-th"></i>
            </div>
            <mat-form-field *ngIf="!multipleSelectEnabled">
                <mat-label>{{ 'filter_bar.production_line' | translate }}</mat-label>
                <mat-select class="production_line_select" panelClass="production_line" (selectionChange)="filterChanged()" [(ngModel)]="activeFilters.deviceId">
                    <mat-optgroup *ngFor="let group of productionLinesDeviceList">{{group.plant.description}}
                        <mat-option *ngFor="let item of group.devices" [value]="item.id" >{{item.label}}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="multipleSelectEnabled">
                <mat-label>{{ 'filter_bar.production_line' | translate }}</mat-label>
                <mat-select [formControl]="deviceFormControl" multiple panelClass="multiselect-panel">
                    <mat-select-trigger>
                        {{deviceFormControl.value ? deviceFormControl.value[0].label : ''}}
                        <span *ngIf="deviceFormControl.value?.length > 1" class="additional-selection">
                            (+{{deviceFormControl.value.length - 1}} {{deviceFormControl.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-optgroup *ngFor="let group of productionLinesDeviceList">{{group.plant.description}}
                        <mat-option *ngFor="let item of group.devices" [value]="item" class="multiselect-option">{{item.label}}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!disableFilter() && !isLinePage() && !isQualityDashboard() && activeFilters.showOrderList" >
                <mat-label>{{ 'filter_bar.order_code' | translate }}</mat-label>
                <mat-select [disabled]="isAllProducts !== false" class="production_code_select" panelClass="production_code" (selectionChange)="filterChanged()" [(ngModel)]="activeFilters.orderCoreId">
                    <mat-option *ngFor="let item of activeFilters.orderCoreList" [value]="item.id">{{item.label}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!disableFilter() && !isLinePage() && !isQualityDashboard()" [ngClass]="{'product-field': activeFilters.showOrderList}">
                <mat-label>{{ 'filter_bar.product_code' | translate }}</mat-label>
                <mat-select [disabled]="isAllOrdersCore !== false" class="production_code_select" panelClass="production_code" (selectionChange)="filterChanged()" [(ngModel)]="activeFilters.productId">
                    <mat-option *ngFor="let item of activeFilters.productsList" [value]="item.id">{{item.label}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field *ngIf="!disableFilter() && isLinePage()">
                <mat-label>{{ 'filter_bar.order_code' | translate }}</mat-label>
                <mat-select (selectionChange)="filterChanged()" [(ngModel)]="activeFilters.orderId">
                    <mat-option *ngFor="let item of activeFilters.ordersList" [value]="item.id">{{item.label}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isQualityControl()">
                <mat-label>{{ 'filter_bar.control_type' | translate }}</mat-label>
                <mat-select (selectionChange)="filterChanged()" [(ngModel)]="activeFilters.controlId">
                    <mat-option *ngFor="let item of activeFilters.controlsList" [value]="item.id">{{item.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <form *ngIf="isStopPage()" class="export-report">
            <button type="submit" (click)="downloadReport()">
                <i class="icon-download-report"></i>
            </button>
        </form>
        <form *ngIf="isQualityDashboard()" class="export-report">
            <button type="submit" (click)="downloadQCReport()">
                <i class="icon-download-report"></i>
            </button>
        </form>
        <form *ngIf="isOrderPage()" class="export-report">
            <button type="submit" (click)="downloadOrdersExcel()">
                <i class="icon-download-report"></i>
            </button>
        </form>
        <app-date-filter (dateChangeEvent)="dateChanged($event)" [endDate]="activeFilters.dateEnd"
            [startDate]="activeFilters.dateBegin"></app-date-filter>
    </div>
</div>
