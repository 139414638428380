import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "environments/environment";

// export enum Environment {
//     Prod = 'production',
//     Staging = 'staging',
//     Dev = 'dev',
//     Local = 'local'
//   }
  
//   interface Configuration {
//     apiUrl: string;
//     stage: Environment;
//   }
  
  @Injectable({ providedIn: 'root' })
  export class EnvConfigurationService {
    private readonly configUrl = 'configuration/config.json';
    // private configuration$: Observable<Configuration>;
    private configSettings: any = null;

    constructor(private http: HttpClient) {}
  
    // public load(): Observable<Configuration> {
    //   if (!this.configuration$) {
    //     this.configuration$ = this.http
    //       .get<Configuration>(`${this.apiUrl}`)
    //       .pipe(shareReplay(1));
    //   }
    //   return this.configuration$;
    // }
    

    get settings() {
      return this.configSettings;
    }

    public load(): Promise<any> {
        return new Promise((resolve, reject) => {
        this.http.get(this.configUrl).subscribe((response: any) => {
            this.configSettings = response;

            if (!this.configSettings) {
                this.configSettings = environment;
            }
            resolve(true);
        }, (error: any) => {
          console.error(`Error reading configuration file: ${error.message}`);
          if (!this.configSettings) {
              this.configSettings = environment;
          }
          resolve(false);
      });
    });
    }
  }