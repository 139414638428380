<div class="orders-page">
    <app-filter-bar></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder">
        <div class="content" id="chartContainer">
            <div class="filter-container">
                <span class="filter-label">{{'filter_bar.filter_for' | translate}}:</span>
                <mat-form-field class="filter-status">
                    <mat-select (selectionChange)="filterStatusChanged()" [(ngModel)]="statusSelected">
                        <mat-option *ngFor="let status of ['all','running','suspended','done']" [value]="status" >{{'orders.status.' + status | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="orders-chart">
                <div class="orders-container">
                    <span class="column-title">{{'orders.title' | translate}}</span>
                    <div *ngFor="let order of analytics" class="order-code">
                        <div class="detail {{order.status}}" (click)="selectOrder(order.id)">
                            <span>{{order.code}}</span>
                            <i class="fa fa-pause-circle-o" *ngIf="order.status === 'suspended'"></i>
                            <i class="fa fa-flag" *ngIf="order.status === 'done'"></i>
                            <i class="fa fa-spinner" *ngIf="order.status === 'running'"></i>
                        </div>
                    </div>
                </div>
                <div id="chart"></div>
                <div class="orders-analytics">
                    <div class="column-title">
                        <span>{{'orders.columns_header.items' | translate}}</span>
                        <span>{{'orders.columns_header.scraps' | translate}}</span>
                        <span>{{'orders.columns_header.stops' | translate}}</span>
                    </div>
                    <div *ngFor="let order of analytics">
                        <div class="order-data">
                            <span>{{order.items}} / {{order.target}}</span>
                            <span>{{order.scraps}}</span>
                            <span>{{calculatePercentage(order.stopTime, order.totalTime)}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="legend-container">
                <span><i class="square production"></i>{{'realtime.production_time' | translate}}</span>
                <span><i class="square planned"></i>{{'stops.planned_stop' | translate}}</span>
                <span><i class="square unplanned"></i>{{'stops.unplanned_stop' | translate}}</span>
                <span><i class="square not-specified"></i>{{'stops.not_specified_stop' | translate}}</span>
                <span><i class="square change-activity"></i>{{'stops.change_activity_stop' | translate}}</span>
            </div>
        </div>
    </div>
</div>