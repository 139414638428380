<div class="settings-page">
    <div class="wrapper">
        <h2 class="title">{{ 'setting.kpi_settings.title' | translate }}</h2>
        <button class="color-button" (click)="saveOrderKPI()">{{ 'setting.kpi_settings.save' | translate }}</button>
    </div>

    <div cdkDropList class="KPI-list" (cdkDropListDropped)="drop($event)">
        <div class="KPI-box" *ngFor="let kpi of kpis" cdkDrag>
            <i class="fa fa-bars"></i>
            <span class="KPI-item">{{kpi.order}}</span>
            <span class="KPI-description" *ngIf="kpi.KPISetting.id !== 4 || (kpi.KPISetting.id === 4 && !oae)">{{ 'setting.kpi_title.'+ kpi.KPISetting.description | translate}}</span>
            <span class="KPI-description" *ngIf="kpi.KPISetting.id === 4 && oae">{{ 'setting.kpi_title.oae' | translate}}</span>
            <!-- <mat-form-field class="kpi-input">
                <input matInput type="text" placeholder="{{kpi.KPISetting.description}}" [(ngModel)]="kpi.label">
                <button mat-button *ngIf="kpi.label" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-hint>{{ 'setting.insert-description' | translate}}</mat-hint>
              </mat-form-field> -->
            <span class="KPI-text">{{ 'setting.kpi_description.'+ kpi.KPISetting.description | translate}}</span>
            <mat-slide-toggle class="slide-toggle kpi-item" [checked]="oae" *ngIf="kpi.KPISetting.id === 4"
                (change)="changeOaeSettings()">
                OAE
            </mat-slide-toggle>
        </div>
    </div>
    
    <div class="wrapper">
        <!-- <h2 class="title">{{ 'setting.setting-kpi' | translate }}</h2> -->
        <h2 class="title">{{ 'setting.date_filter.title' | translate }}</h2>
        <button class="color-button" (click)="saveFilteringOptions()">{{ 'setting.date_filter.save' | translate }}</button>
    </div>

    <div cdkDropList class="Settings-list">
        <div class="Settings-box" *ngFor="let filteringOpt of dateFiltersOptions;  index as index;">
            <span class="Settings-item"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
            <span class="Settings-description">{{ 'setting.datefilters_title.'+ filteringOpt.DashboardSetting.label | translate}}</span>
           <span class="Settings-text">{{ 'setting.datefilters_description.'+ filteringOpt.DashboardSetting.label | translate}}</span>
            <mat-slide-toggle class="slide-toggle Settings-item" [checked]="isChecked(filteringOpt)" *ngIf="isSwitch(filteringOpt.DashboardSetting)"
                (change)="switchSetting(filteringOpt, index)"></mat-slide-toggle>
        </div>
    </div>
</div>