<div class="loader-container" *ngIf="lockRequest">
    <img src="{{getLoaderSrc()}}">
</div>
<app-filter-bar></app-filter-bar>
<div class="stops-page">
    <div class="content">
        <div class="percentage-container">
            <app-percentage-bar [data]="percentageBarData">
            </app-percentage-bar>
        </div>
        <app-stops-charts
            #stopAbstractCharts
            class="main"
            (change)="changeStopsChartsView($event)"
            [title]="'Stop Abstract'">
        </app-stops-charts>
        <app-stops-charts
            #plannedStopsCharts
            (change)="changeStopsChartsView($event)"
            [title]="'planned'">
        </app-stops-charts>
        <app-stops-charts
            #unplannedStopsCharts
            (change)="changeStopsChartsView($event)"
            [title]="'unplanned'">
        </app-stops-charts>
        <app-stops-table 
            [stopsPerPage]="stopsLimit" 
            (dataUpdated)="getStops()" 
            [stops]="stopsTable">
        </app-stops-table>
    </div>
</div>
