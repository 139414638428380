<h3>{{ 'date_filter.select_schedule' | translate }}</h3>
<div class="schedule-list" *ngIf="schedules && schedules.length">
  <div class="select-all">
    <span (click)="selectAll()">{{(allSelected ? 'date_filter.clear_all' : 'date_filter.select_all') | translate}}</span>
  </div>
  <div *ngFor="let schedule of schedules; let i=index" 
    (click)="addSchedule(i)">
    <div class="schedule-item">
      <span class="date">{{schedule.dateFrom | dateTimeFormat: 'shortDate'}}</span> 
      {{schedule.dateFrom | dateTimeFormat: 'shortTime'}} - {{schedule.dateTo | dateTimeFormat: 'shortTime'}}
      <svg *ngIf="_schedules[i]" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    </div>

  </div>
</div>
<div class="schedule-list" *ngIf="!schedules || !schedules.length">
  {{ 'date_filter.no_schedules' | translate }}
</div>
<div class="dialog-actions">
    <button (click)="cancel()" class="color-button">{{ 'dialog_stop_note.cancel' | translate }}</button>
    <button (click)="selectedSchedule()" class="color-button" [disabled]='!_schedules || _schedules.length <= 0' >{{ 'dialog_stop_note.ok' | translate}}</button>
</div>

<!--[ngClass]="{'selected-schedule': _schedules[i], 'schedule-item':!_schedules[i]}"-->