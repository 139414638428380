import { TranslateService } from '@ngx-translate/core';
import { DevicesService } from '../../../services/devices.service';
import { OeeParams, Stats } from '../../../models/stats';
import { StatsService } from '../../../services/stats.service';
import { CronGranularity, dataGranularities, RawData } from '../../../models/raw-data';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import * as moment from 'moment/moment';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { AmChartsService } from '@amcharts/amcharts3-angular';

import { Session } from 'app/models/session';

import { RawDataService } from 'app/services/raw-data.service';
import { SessionService } from 'app/services/session.service';

import { NavbarService } from '../../../services/navbar.service';
import { Device, SensorType } from '../../../models/device';
import { LoginService } from 'app/services/login.service';
import { UserRole } from 'app/models/user';
import { MatDialog } from '@angular/material';
import { EditSessionDialogComponent } from 'app/components/ui/dialog-edit-session/dialog-edit-session.component';
import { ProductionSchedule } from 'app/models/schedules';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';

@Component({
    templateUrl: './pieces-scraps.component.html',
    styleUrls: ['./pieces-scraps.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PiecesScrapsComponent extends PageWithLoader implements OnInit, OnDestroy {
    @ViewChild('begin') beginTemplate: TemplateRef<any>;
    @ViewChild('end') endTemplate: TemplateRef<any>;
    @ViewChild('duration') durationTemplate: TemplateRef<any>;
    @ViewChild('product') productTemplate: TemplateRef<any>;
    @ViewChild('headerPieces') headerPiecesTemplate: TemplateRef<any>;
    @ViewChild('headerScraps') headerScrapsTemplate: TemplateRef<any>;
    @ViewChild('pieces') piecesTemplate: TemplateRef<any>;
    @ViewChild('scraps') scrapsTemplate: TemplateRef<any>;
    @ViewChild('note') noteTemplate: TemplateRef<any>;
    @ViewChild('action') actionTemplate: TemplateRef<any>;
    @ViewChild('table') table: any;
    // to lock data request if is already processing
    private _filterSubscription;
    activeGranularity: CronGranularity;
    activeGranularityMinutes: number;
    granularities: CronGranularity[];
    chartsReady = false;
    device: Device;
    graphType;
    /**
    * granularity not allowed for the current time filter
    *
    * @type {number[]}
    * @memberOf PiecesScrapsComponent
    */
    disabledGranularities: number[] = [];
    rawData: RawData[];
    sessions: Session[];
    stats: Stats;
    oee: OeeParams;
    avg = 0;
    targetSpeed = 0;
    totalValue = 0;
    availableData = false;
    nShifts: number = 0;
    lockRequest = true;
    columns = [];
    userRole: number;
    private chartValues: any;
    private chartScraps: any;
    constructor(
        private AmCharts: AmChartsService,
        private activeFilters: ActiveFiltersService,
        private navbar: NavbarService,
        private _dataService: RawDataService,
        private router: Router,
        private route: ActivatedRoute,
        private _statsService: StatsService,
        private _translate: TranslateService,
        private _devicesService: DevicesService,
        private _sessionService: SessionService,
        private _storage: LoginService,
        private _dialog: MatDialog,
        _themeService: ThemeService
    ) { super(_themeService) }

    /**
    * Set current granularity
    *
    * @param {number} granularityMinutes
    *
    * @memberOf PiecesScrapsComponent
    */
    async setGranularity(granularity: CronGranularity) {
        if (this.disabledGranularities.indexOf(granularity.granularityMinutes) !== -1) {
            return;
        }
        this.activeGranularity = granularity;
        this.activeGranularityMinutes = granularity.granularityMinutes;
        await this.getData();
    }

    /**
    * Compute a reasonable granularity based on current time filters
    *
    *
    * @memberOf PiecesScrapsComponent
    */
    _computeGranularity() {
        const currentInterval = this.activeFilters.dateEnd.getTime() - this.activeFilters.dateBegin.getTime();
        const currentIntervalMinutes = currentInterval / 1000 / 60;

        // choose best granularity for the given time filter
        let bestGranularity: CronGranularity;
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 75)) {
                continue;
            } else {
                bestGranularity = granularity;
                break;
            }
        }
        this.activeGranularity = bestGranularity;
        this.activeGranularityMinutes = bestGranularity.granularityMinutes;

        // find disabled granularities for the current time filter
        // i.e. too low or too high granularities
        this.disabledGranularities = [];
        const disabledGranularities: number[] = [];
        for (const granularity of this.granularities) {
            const granularityMinutes = granularity.granularityMinutes;
            if (granularityMinutes < (currentIntervalMinutes / 100)) {
                disabledGranularities.push(granularityMinutes);
            } else if (granularityMinutes > (currentIntervalMinutes / 3)) {
                disabledGranularities.push(granularityMinutes);
            }
        }
        this.disabledGranularities = disabledGranularities;
    }

    async ngOnInit() {
        this.graphType = this.route.snapshot.data.type;
        if (this.graphType === 'pieces') {
            this._translate.get('pieces.pieces').subscribe((res: string) => {
                this.navbar.setTitle(res);
            });
        } else {
            this._translate.get('pieces.scraps').subscribe((res: string) => {
                this.navbar.setTitle(res);
            });
        }

        this.userRole = parseInt(this._storage.readFromLocalStorage('role'));
        // Prefetch all labels needed from table initialization
        this._translate.stream([
            'stops.date',
            'stops.duration',
            'stops.product',
            'pieces.pieces',
            'pieces.scraps',
            'stops.note',
        ]).subscribe((translations) => {
            this.columns = [
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: 'Inizio',
                    cellTemplate: this.beginTemplate,
                    cellClass: 'begin-cell',
                    width: 50,
                    sortable: false
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: 'Fine',
                    cellTemplate: this.endTemplate,
                    cellClass: 'end-cell',
                    width: 50,
                    sortable: false
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: translations['stops.duration'],
                    prop: 'totalTime',
                    cellTemplate: this.durationTemplate,
                    cellClass: 'duration-cell',
                    width: 50,
                    sortable: false
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: translations['stops.product'],
                    cellTemplate: this.productTemplate,
                    cellClass: 'product-cell',
                    width: 100,
                    sortable: false
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    prop: 'itemsTotal',
                    cellClass: 'pieces-cell',
                    cellTemplate: this.piecesTemplate,
                    headerTemplate: this.headerPiecesTemplate,
                    width: 50,
                    sortable: false
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    prop: 'scraps',
                    cellClass: 'pieces-cell',
                    cellTemplate: this.scrapsTemplate,
                    headerTemplate: this.headerScrapsTemplate,
                    width: 50,
                    sortable: false
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: translations['stops.note'],
                    cellTemplate: this.noteTemplate,
                    prop: 'note',
                    cellClass: 'note-cell',
                    sortable: false
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    name: '',
                    prop: 'updatedAt',
                    cellClass: 'action-cell',
                    cellTemplate: this.actionTemplate,
                    width: 50,
                    sortable: false
                },
            ];
        });

        // build allowed granularities
        this._buildGranularities();

        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        // set a default deviceId filter if it's not already set
        await this.activeFilters.initializeActiveDevice();

        this._computeGranularity();

        this.getData();
    }

    ngOnDestroy() {
        if (this.AmCharts && this.chartValues) {
            this.AmCharts.destroyChart(this.chartValues);
        }
        if (this.AmCharts && this.chartScraps) {
            this.AmCharts.destroyChart(this.chartScraps);
        }
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    /**
    * Build allowed granularities array
    *
    *
    * @memberOf PiecesScrapsComponent
    */
    private _buildGranularities() {
        const allowedGranularities = [60, 1440, 10080, 46080];

        this.granularities = dataGranularities.filter(granularity => {
            return allowedGranularities.indexOf(granularity.granularityMinutes) !== -1;
        });
    }
    /**
    * Function to navigate to another view
    *
    * @param {any} path
    * @memberof RealtimeComponent
    */
    navigate(path) {
        this.router.navigateByUrl(path);
    }

    onFilterChanged() {
        this._computeGranularity();
        this.getData();
    }

    /**
    * Function to get data based on the selected filter and draw the chart.
    *
    * @memberof HomeComponent
    */
    async getData() {
        this.lockRequest = true;
        await this._devicesService.getDevices();
        this.device = this._devicesService.getDeviceInfo(this.activeFilters.deviceId);
        this.stats = await this._statsService.getStats(
                                                this.activeFilters.dateBegin.toISOString(), 
                                                this.activeFilters.dateEnd.toISOString(), 
                                                this.activeFilters.deviceId, 
                                                this.activeFilters.productId,
                                                null,
                                                null,
                                                null,
                                                this.activeFilters.schedules,
                                                this.activeFilters.orderCoreId);
        this.sessions = await this._sessionService.getAll(
                                                this.activeFilters.dateBegin.toISOString(), 
                                                this.activeFilters.dateEnd.toISOString(), 
                                                this.activeFilters.deviceId, 
                                                this.activeFilters.productId,
                                                this.activeFilters.schedules);
        this.oee = await this._statsService.getAggregateOee(
                                                this.activeFilters.dateBegin.toISOString(), 
                                                this.activeFilters.dateEnd.toISOString(),
                                                [this.activeFilters.deviceId],
                                                null,
                                                null,
                                                null,
                                                this.activeFilters.schedules)


        if (this.device.Unipi.sensorType === SensorType.delta) {
            this._drawSessionsStats(this.sessions);
            this.lockRequest = false;
            const data = this._formatSessionsForChart(this.sessions);
            this.drawChart(data);
        } else {
            this.rawData = await this._dataService.getAll(
                                                this.activeFilters.dateBegin.toISOString(), 
                                                this.activeFilters.dateEnd.toISOString(), 
                                                this.activeFilters.deviceId, 
                                                this.activeFilters.productId, 
                                                this.activeGranularity.granularityMinutes,
                                                this.activeFilters.schedules,
                                                this.activeFilters.orderCoreId);
            // get stats
            // this.getStats(this.activeFilters.dateBegin.toISOString(), this.activeFilters.dateEnd.toISOString());
            // ---------
            this._drawStats(this.rawData);
            this.lockRequest = false;

            const data = this._formatRawDataForChart(this.rawData);

            this.drawChart(data);
        }

        this.nShifts = this.activeFilters.schedules && this.activeFilters.schedules.length ? JSON.parse(this.activeFilters.schedules).length : 0;
    }

    private _drawSessionsStats(data: Session[]) {
        let values = 0;
        let scraps = 0;

        data.forEach(item => {
            if (item.itemsDeclared) {
                values += item.itemsDeclared;
            } else if (item.itemsTotal) {
                values += item.itemsTotal;
            }
            if (item.scrapsDeclared) {
                scraps += item.scrapsDeclared;
            } else if (item.scraps) {
                scraps += item.scraps;
            }
        });
        // valuesAverage in hours is piecesPerMin * 60, but for round it, we multiply *10 it and we round the values, then we divide it by 10 to get the "number.x" value
        const valuesAverage = Math.round(this.stats.piecesPerMin * this.activeGranularityMinutes * 10) / 10;
        const scrapsAverage = Math.round(scraps / data.length);
        const avgGuide = [{
            above: true,
            balloonColor: '#5cc6d4',
            labelRotation: 1.2,
            position: 'right',
            boldLabel: true,
            inside: true,
            color: '#555',
            lineAlpha: 1,
            lineColor: '#5cc6d4',
            lineThickness: 1.5,
            id: 'AvgLine'
        }];

        if (this.graphType === 'pieces') {
            this.avg = valuesAverage;
        }
        if (this.graphType === 'scraps') {
            this.avg = scrapsAverage;
        }
        if (this.avg > 0) {
            this.availableData = true;
        } else {
            this.availableData = false;
        }
    }

    private _drawStats(data: RawData[]) {
        let timeIntervalMinutesValues = 0;
        let timeIntervalMinutesScraps = 0;
        let values = 0;
        let scraps = 0;

        data.forEach(item => {
            if (item.valuesTotal) {
                values += item.valuesTotal || item.values;
                timeIntervalMinutesValues += this.activeGranularityMinutes;
            }
            if (item.scrapsTotal) {
                scraps += item.scrapsTotal;
                timeIntervalMinutesScraps += this.activeGranularityMinutes;
            }
        });

        const valuesAverage = Math.round(this.stats.piecesPerMin * 60 * 10) / 10;
        const scrapsAverage = Math.round(scraps * this.activeGranularity.granularityMinutes / timeIntervalMinutesScraps);
        if (this.activeFilters.productId && this.sessions && this.sessions.length && this.sessions[0].targetSpeed) {
            this.targetSpeed = Math.round(this.sessions[0].targetSpeed * 60 * 10) / 10;
        } else {
            this.targetSpeed = Math.round(this.oee.idealSpeed * 60 * 10) / 10;
        }

        if (this.graphType === 'pieces') {
            this.avg = valuesAverage;
            this.totalValue = values;
        }
        if (this.graphType === 'scraps') {
            this.avg = scrapsAverage;
            this.totalValue = scraps;
        }
        if (this.avg > 0) {
            this.availableData = true;
        } else {
            this.availableData = false;
        }
    }

    
    /**
    * Format an array of raw data to the format used by the chart
    *
    * @private
    * @param {RawData[]} rawData
    * @returns {*}
    *
    * @memberOf RealtimeComponent
    */
    private _formatRawDataForChart(rawData: RawData[]): any {

        let data = [];

        this._translate.stream([
            'times.from',
            'times.to',
            'times.week'
        ]).subscribe((translations) => {
            if (this.activeFilters.schedules && this.activeFilters.schedules.length) {
                const _schedules: ProductionSchedule[] = JSON.parse(this.activeFilters.schedules);
                let groups: {fromGroup: Date, toGroup: Date, datas: RawData[]}[] = [];
                
                for (let schedule of _schedules) {
                    const from = new Date(schedule.dateFrom).getTime();
                    const to = new Date(schedule.dateTo).getTime();

                    groups.push({
                        fromGroup: schedule.dateFrom,
                        toGroup: schedule.dateTo,
                        datas: rawData.filter( (d) => new Date(d.dateFrom).getTime() >= from && new Date(d.date).getTime() <= to)
                    });
                }

                let groupMap = groups.map(_group => {
                    let axisDate;
                    let tooltipDate;
                    
                    axisDate = moment(_group.fromGroup).locale(this._translate.currentLang).toDate();
                    tooltipDate = `${moment(_group.fromGroup).locale(this._translate.currentLang).format('DD MMMM HH:mm')}\n-\n${moment(_group.toGroup).locale(this._translate.currentLang).format('DD MMMM HH:mm')}`;
                    
                    let value = {
                        axisDate: axisDate,
                        balloonDate: tooltipDate,
                        values: 0,
                        scraps: 0,
                        valuesDetails1: 0,
                        scrapsDetails1: 0
                    };
                    _group.datas.forEach( item => {
                        value.values += item.valuesTotal || item.values ? item.valuesTotal || item.values : 0;
                        value.scraps += item.scrapsTotal ? item.scrapsTotal : 0;

                        if (item.scrapsDetail) {
                            for (const detail of Object.keys(item.scrapsDetail)) {
                                value['scrapsDetails' + detail] += item.scrapsDetail[detail];
                            }
                        }    

                        if (item.valuesDetail) {
                            for (const detail of Object.keys(item.valuesDetail)) {
                                value['valuesDetails' + detail] += item.valuesDetail[detail];
                            }
                        }
                    });

                    return value;
                });
                data.push(...groupMap);
            } else {
                let groupMap = rawData.map((item, index) => {
                    // compute date of sampled data switching for granularity
                    let axisDate;
                    let tooltipDate;
                    switch (this.activeGranularityMinutes) {
                        case 1440:
                            if (index === 0) return;
                            else if (index === (rawData.length - 1)) item.date = moment(item.date).add(1, 's').toDate();
                            axisDate = moment(item.dateFrom).locale(this._translate.currentLang).format('DD MMM');
                            tooltipDate = moment(item.dateFrom).locale(this._translate.currentLang).format('DD MMMM');
                            break;
                        case 10080:
                            if (index === (rawData.length - 1)) item.dateFrom = moment(item.dateFrom).add(1, 's').toDate();
                            axisDate = moment(item.dateFrom).format('YYYY') + ' ' + translations['times.week'] + ' ' + moment(item.dateFrom).format('w');
                            tooltipDate = translations['times.from'] + ' ' + moment(item.dateFrom).format('DD/MM') + ' ' + translations['times.to'] + ' ' + moment(item.date).format('DD/MM');
                            break;
                        case 46080:
                            if (index === (rawData.length - 1)) item.date = moment(item.date).add(1, 's').toDate();
                            axisDate = moment(item.dateFrom).format('MM/YYYY') + ' - ' + moment(item.date).format('MM/YYYY');
                            tooltipDate = translations['times.from'] + ' ' + moment(item.dateFrom).format('MM/YYYY') + ' ' + translations['times.to'] + ' ' + moment(item.date).format('MM/YYYY');
                            break;
                        default:
                            axisDate = moment(item.dateFrom).valueOf();
                            tooltipDate = moment(item.dateFrom).format('HH:mm') + ' - ' + moment(item.date).format('HH:mm');
                            break;
                    }
    
    
                    let value = {
                        axisDate: axisDate,
                        balloonDate: tooltipDate,
                        values: item.valuesTotal || item.values,
                        scraps: item.scrapsTotal,
                        valuesDetails1: item.valuesTotal || item.values,
                        scrapsDetails1: item.scrapsTotal,
                    };
    
                    if (item.scrapsDetail) {
                        for (const detail of Object.keys(item.scrapsDetail)) {
                            value['scrapsDetails' + detail] = item.scrapsDetail[detail];
                        }
                    }
    
                    if (item.valuesDetail) {
                        for (const detail of Object.keys(item.valuesDetail)) {
                            value['valuesDetails' + detail] = item.valuesDetail[detail];
                        }
                    }
    
                    return value;
                });
                data.push(...groupMap);
            }
        });
        data = data.filter(Boolean);
        return data;
    }

    /**
    * Format an array of sessions to the format used by the chart
    *
    * @private
    * @param {Session[]} sessions
    * @returns {*}
    *
    * @memberOf RealtimeComponent
    */
    private _formatSessionsForChart(sessions: Session[]): any {

        const data = [];
        this._translate.stream([
            'times.from',
            'times.to',
            'times.week'
        ]).subscribe(() => {
            data.push(...sessions.map(item => {
                let label = '';
                if (item.Product) {
                    // label = 'PROD. ' + session.Product.code;
                    label = this._translate.instant('realtime.product', {
                        code: item.Product.code
                    });
                } else {
                    label = this._translate.instant('realtime.not_defined');
                }
                return {
                    product: label,
                    balloonDate: moment(item.beginAt).format('DD/MM HH:mm') + ' - ' + moment(item.endAt).format('DD/MM HH:mm'),
                    // balloonDate: translations['times.from'] + ' ' + moment(item.beginAt).format('DD/MM HH:mm') + ' ' + translations['times.to'] + ' ' + moment(item.endAt).format('DD/MM HH:mm'),
                    values: item.itemsTotal,
                    scraps: item.scraps
                };
            }));
        });

        return data;
    }

    /**
    * Function to draw the chart
    *
    * @param {any} data
    * @memberof RealtimeComponent
    */
    drawChart(data) {
        const chartDefaultOptions = {
            type: 'serial',
            theme: 'light',
            hideCredits: true,
            categoryField: 'axisDate',
            addClassNames: true,
            dataProvider: data,
            fontFamily: 'Roboto',
            columnSpacing: 0,
            categoryAxis: {
                gridAlpha: 0,
                fontSize: 12,
                autoRotateCount: 12,
                autoRotateAngle: 45
            },
            zoomOutButtonAlpha: 0,
            zoomOutText: '',
            zoomOutButtonImageSize: 0,
            legendSettings: {
                labelText: ''
            },
            panelsSettings: {
                marginLeft: 25,
                marginRight: 25,
                marginTop: 20,
                marginBottom: 0
            },
            guides: [{
                above: true,
                balloonColor: '#5cc6d4',
                labelRotation: 1.2,
                position: 'right',
                boldLabel: true,
                inside: true,
                color: '#555',
                lineAlpha: 1,
                lineColor: '#5cc6d4',
                lineThickness: 1.5,
                id: 'AvgLine',
                label: this._translate.instant('pieces.average', {
                    value: this.avg,
                    granularity: this._translate.instant('granularity.hour')
                }),
                value: this.avg
            },
            {
                above: true,
                balloonColor: '#5cc6d4',
                labelRotation: 1.2,
                position: 'left',
                boldLabel: true,
                inside: true,
                color: '#555',
                lineAlpha: 1,
                lineColor: '#11c615',
                lineThickness: 1.5,
                id: 'AvgLine',
                label: this._translate.instant('pieces.targetSpeed', { 
                    value: Math.round(this.targetSpeed),
                    granularity: this._translate.instant('granularity.hour')
                }),
                value: this.targetSpeed
            }],
            chartCursorSettings: {
                enabled: true,
                pan: false,
                bulletsEnabled: true,
                valueBalloonsEnabled: true,
                graphBulletSize: 1,
                valueLineBalloonEnabled: false,
                valueLineEnabled: false,
                categoryBalloonEnabled: true,
                categoryLineEnabled: false,
                valueLineAlpha: 0.5,
                fullWidth: false,
                cursorAlpha: 0
            },
            chartScrollbarSettings: {
                enabled: false
            },
            valueAxes: [{
                stackType: 'regular',
                minimum: 0,
                showFirstLabel: false,
                autoGridCount: false,
                color: '#4b4b4b',
                boldLabels: true,
                fontSize: 12,
                includeGuidesInMinMax: true
            }],
            export: {
                enabled: true
             }
        };
        
        if (this.device.Unipi.sensorType === SensorType.delta) {
            chartDefaultOptions.categoryField = 'product';
            chartDefaultOptions.guides[0].label = this._translate.instant('pieces.average', {
                value: this.avg,
                granularity: this._translate.instant('granularity.session')
            });
        }

        if (this.activeGranularityMinutes < 1440 && this.device.Unipi.sensorType !== SensorType.delta) {
            chartDefaultOptions.categoryAxis['minPeriod'] = this.activeGranularity.granularityMinutes === 60 ? 'hh' : 'DD';
            chartDefaultOptions.categoryAxis['parseDates'] = true;
            chartDefaultOptions.categoryAxis['dateFormats'] = [
                { period: 'fff', format: 'JJ:NN:SS' },
                { period: 'ss', format: 'JJ:NN:SS' },
                { period: 'mm', format: 'JJ:NN' },
                { period: 'hh', format: 'JJ:NN' },
                { period: 'DD', format: 'DD/MM' },
                { period: 'WW', format: 'DD/MM' },
                { period: 'MM', format: 'MM/YYYY' },
                { period: 'YYYY', format: 'YYYY' }
            ];
        }

        const valuesOptions = _.cloneDeep(chartDefaultOptions);
        const scrapsOptions = _.cloneDeep(chartDefaultOptions);

        valuesOptions['colors'] = ['#AACC66', '#1777BD', '#1FA1FF', '#1777BD'];
        scrapsOptions['colors'] = ['#D7510F', '#730202', '#590202', '#260101'];

        if (this.device.Unipi.sensorType === SensorType.delta) {
            valuesOptions['graphs'] = [{
                fillAlphas: 0.8,
                lineAlpha: 0.2,
                type: 'column',
                valueField: 'values',
                columnWidth: 0.7,
                balloonFunction: (balloon) => {
                    return balloon.dataContext.balloonDate + '<br/><b style="font-size: 15px; font-family: Roboto; margin-top: 5px;">' + balloon.values.value + '</b>';
                }
            }];
            scrapsOptions['graphs'] = [{
                fillAlphas: 0.8,
                lineAlpha: 0.2,
                type: 'column',
                valueField: 'scraps',
                columnWidth: 0.7,
                balloonFunction: (balloon) => {
                    return balloon.dataContext.balloonDate + '<br/><b style="font-size: 15px; font-family: Roboto; margin-top: 5px;">' + balloon.values.value + '</b>';
                }
            }]
        } else {
            valuesOptions['graphs'] = [{
                fillAlphas: 0.8,
                lineAlpha: 0.2,
                type: 'column',
                valueField: 'valuesDetails1',
                columnWidth: 0.7,
                balloonFunction: (balloon) => {
                    return balloon.dataContext.balloonDate + '<br/><b style="font-size: 15px; font-family: Roboto; margin-top: 5px;">' + balloon.values.value + ' ' + this._translate.instant('pieces.pieces') + ' 1 </b>';
                }
            }, {
                fillAlphas: 0.8,
                lineAlpha: 0.2,
                type: 'column',
                valueField: 'valuesDetails2',
                columnWidth: 0.7,
                balloonFunction: (balloon) => {
                    return balloon.dataContext.balloonDate + '<br/><b style="font-size: 15px; font-family: Roboto; margin-top: 5px;">' + balloon.values.value + ' ' + this._translate.instant('pieces.pieces') + ' 2 </b>';
                }
            }];
            scrapsOptions['graphs'] = [{
                fillAlphas: 0.8,
                lineAlpha: 0.2,
                type: 'column',
                valueField: 'scrapsDetails1',
                columnWidth: 0.7,
                balloonFunction: (balloon) => {
                    return balloon.dataContext.balloonDate + '<br/><b style="font-size: 15px; font-family: Roboto; margin-top: 5px;">' + balloon.values.value + ' ' + this._translate.instant('pieces.scraps') + ' 1 </b>';
                }
            }, {
                fillAlphas: 0.8,
                lineAlpha: 0.2,
                type: 'column',
                valueField: 'scrapsDetails2',
                columnWidth: 0.7,
                balloonFunction: (balloon) => {
                    return balloon.dataContext.balloonDate + '<br/><b style="font-size: 15px; font-family: Roboto; margin-top: 5px;">' + balloon.values.value + ' ' + this._translate.instant('pieces.scraps') + ' 2 </b>';
                }
            }];
        }
        // this.chartsReady = false;

        this.chartValues = this.AmCharts.makeChart('chart-values', valuesOptions);
        this.chartScraps = this.AmCharts.makeChart('chart-scraps', scrapsOptions);

        // hide graphs to avoid flickering when changing scales
        // this.chartScraps.hideGraph(this.chartScraps.graphs[0]);
        // this.chartValues.hideGraph(this.chartValues.graphs[0]);

        // normalize charts to the same y range
        // let maximum = 0;
        // if (this.chartValues.valueAxes[0].max > maximum) {
        //     maximum = this.chartValues.valueAxes[0].max;
        // }
        // if (this.chartScraps.valueAxes[0].max > maximum) {
        //     maximum = this.chartScraps.valueAxes[0].max;
        // }
        // this.chartValues.valueAxes[0].maximum = maximum;
        // this.chartScraps.valueAxes[0].maximum = maximum;

        // show graphs
        // this.chartScraps.showGraph(this.chartScraps.graphs[0]);
        // this.chartScraps.showGraph(this.chartValues.graphs[0]);
        // this.chartsReady = true;

    }

    isShiftSelected() {
        return this.activeFilters.schedules && this.activeFilters.schedules.length;
    }

    isGranularityAvailable() {
        if (this.activeFilters.schedules && this.activeFilters.schedules.length) {
            return false;
        }

        if (this.device && this.device.Unipi) {
            if (this.device.Unipi.sensorType === SensorType.delta) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    /**
     * Open a modal to set the stop's error
     *
     * @param {number} stopId
     *
     * @memberOf StopsTableComponent
     */
    setEditSessionModal(index: number, session: Session) {
        if (this.userRole === UserRole.supervisor || !session.endAt) {
            return;
        }
        const dialogRef = this._dialog.open(EditSessionDialogComponent, {
            width: '700px',
            data: session
        });

        dialogRef.afterClosed().subscribe(updated => {
            if (updated) {
                this.sessions[index] = updated;
                this.sessions = [...this.sessions];
                if (this.device.Unipi.sensorType === SensorType.delta) {
                    this._drawSessionsStats(this.sessions);
                    const data = this._formatSessionsForChart(this.sessions);
                    this.drawChart(data);
                }
            }
        });
    }

};
