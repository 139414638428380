<div class="item">
        <div class="percentage">
            <p>{{stop.percentage}}%</p>
        </div>
        <div class="bar-container">                        
            <div class="bar" [style.width.%]="stop.percentage > 1 ? stop.percentage : 1" [ngClass]="{'planned': isPlanned, 'unplanned': isPlanned === false}"
                (mousemove)="tooltip.show($event)" (mouseout)="tooltip.hide($event)">
            </div>
            <tooltip *ngIf="isFrequencyChart" #tooltip message="{{stop.values}}"></tooltip>
            <tooltip *ngIf="!isFrequencyChart" #tooltip message="{{stop.values | durationPipe}}"></tooltip>
            <p>
                {{stop.text}}
                <span class="tag" *ngIf="stop.tag">
                    <br>
                    ( {{stop.tag}} )
                </span>
            </p>
        </div>
    </div>