<div class="realtime-page">
    <app-filter-bar></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder">
        <div class="content" id="chartContainer">
            <div class="loss-title-container" *ngIf="isShiftSelected()">
                <div class="loss-shifts-number">
                    <span class="shifts-number-text">{{'oee.shifts_selected' | translate}}</span><span class="shifts-number">{{nShifts}}</span>
                </div>
            </div>

            <div id="chart" [style.width.%]="100" [style.height.%]="90"></div>
            <div class="graph-details-container">
                <button class="color-button" [disabled]="!canExclude" (click)="excludeSessions()"
                    [ngClass]="{'not-show': !canExclude && !canExpand}">
                    <i class="fa fa-compress" aria-hidden="true"></i>{{'dialog_scraps.exclude' | translate}}
                </button>
                <div class="granularity">
                    <div *ngIf="activeFilters.granularity">
                        {{ 'filter_bar.legend_pieces' | translate }} <span
                            class="orange">{{activeFilters.granularity | shortDurationPipe}}</span>
                    </div>
                    <div *ngIf="!activeFilters.granularity">
                        {{ 'filter_bar.legend_no_pieces' | translate }}
                    </div>
                </div>
                <button class="color-button" [disabled]="!canExpand" (click)="expandSessions()"
                    [ngClass]="{'not-show': !canExclude && !canExpand}">
                    <i class="fa fa-expand"></i> {{'dialog_scraps.expand' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="bottom-data">

        <div *ngFor="let kpi of kpis" class="bottom-container">

            <!-- PIECES -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 1">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable && !timerMode">{{kFormatter(stats.piecesTotal)}}</h2>
                <span *ngIf="statsAvaiable && timerMode" class="h2" [innerHTML]="uptime | durationPipe:'html'"></span>
                <button *ngIf="!timerMode" (click)="navigate('pieces')">{{ 'realtime.pieces' | translate }}</button>
                <button *ngIf="timerMode" [disabled]="true"
                    class="label-button">{{ 'realtime.production_time' | translate }}</button>
            </div>

            <!-- SCRAPS O DOWNTIME -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 2">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2>
                    <span *ngIf="statsAvaiable && (!canShowScrapsPercentage() || isLastSession()) && !timerMode"
                        class="h2">{{'stops.not_available' | translate}}</span>
                    <span *ngIf="statsAvaiable && canShowScrapsPercentage() && !isLastSession() && !timerMode"
                        class="h2">{{kFormatter(stats.scrapsTotal)}} </span>
                    <span *ngIf="!timerMode" class="percentage" (click)="showScrapsInfoDialog()">
                        <span
                            *ngIf="statsAvaiable && canShowScrapsPercentage() && !isLastSession()">({{stats.scrapsPercentage.toFixed(1)}}
                            %)</span>
                        <span *ngIf="statsAvaiable && (!canShowScrapsPercentage() || isLastSession())"
                            class="info">{{'dialog_scraps.title' | translate}}</span>
                    </span>
                    <span *ngIf="statsAvaiable && timerMode" class="h2"
                        [innerHTML]="downtime | durationPipe:'html'"></span>
                </h2>
                <button *ngIf="!timerMode" (click)="navigate('scraps')"
                    [disabled]="statsAvaiable && (!areSessionsVisible() || !canShowScrapsPercentage() || isLastSession())">{{ 'realtime.scraps' | translate }}</button>
                <button *ngIf="timerMode" [disabled]="true"
                    class="label-button">{{ 'realtime.downtime' | translate }}</button>
            </div>

            <!-- STOPS -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 3">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable">{{stats.stops}}</h2>
                <button (click)="navigate('stops')">{{ 'realtime.stops' | translate }}</button>
            </div>

            <!-- OEE -->
            <div class="data-item" *ngIf="!activeFilters.productId && kpi.KPISettingId === 4 && !oae">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable && oee && oee.oee">{{(oee.oee * 100).toFixed(1)}}<span>%</span></h2>
                <h2 *ngIf="statsAvaiable && (!oee || !oee.oee)">{{'stops.not_available' | translate}}</h2>
                <button [disabled]="statsAvaiable && (!oee || !oee.oee)" (click)="navigate('oee')">Oee</button>
            </div>

            <!-- MTBF -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 5">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable && stats.mtbf && stats.mtbf >= 0"
                    [innerHTML]="stats.mtbf | durationPipe:'html'"></h2>
                <h2 *ngIf="statsAvaiable && (!stats.mtbf || stats.mtbf < 0)">{{'stops.not_available' | translate}}</h2>
                <button (mousemove)="mtbfTooltip.show($event)" (mouseout)="mtbfTooltip.hide($event)"
                    (click)="navigate('mtbf')" [disabled]="statsAvaiable && (!stats.mtbf || stats.mtbf < 0)">
                    {{ 'realtime.mtbf' | translate }}
                </button>
                <tooltip #mtbfTooltip message="{{ 'realtime.mtbf_long' | translate }}"></tooltip>
            </div>

            <!-- MTBR -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 6">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable && stats.mttr && stats.mttr >= 0"
                    [innerHTML]="stats.mttr | durationPipe:'html'"></h2>
                <h2 *ngIf="statsAvaiable && (!stats.mttr || stats.mttr < 0)">{{'stops.not_available' | translate}}</h2>
                <button (mousemove)="mttrTooltip.show($event)" (mouseout)="mttrTooltip.hide($event)"
                    (click)="navigate('mttr')" [disabled]="statsAvaiable && (!stats.mttr || stats.mttr < 0)">
                    {{ 'realtime.mttr' | translate }}
                </button>
                <tooltip #mttrTooltip message="{{ 'realtime.mttr_long' | translate }}"></tooltip>
            </div>

            <!-- UPTIME -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 7">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <span *ngIf="statsAvaiable" class="h2" [innerHTML]="uptime | durationPipe:'html'"></span>
                <button [disabled]="true" class="label-button">{{ 'realtime.production_time' | translate }}</button>
            </div>

            <!-- DOWNTIME -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 8">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2>
                    <span *ngIf="statsAvaiable" class="h2" [innerHTML]="downtime | durationPipe:'html'"></span>
                </h2>
                <button [disabled]="true" class="label-button">{{ 'realtime.downtime' | translate }}</button>
            </div>

            <!-- OAE -->
            <div class="data-item" *ngIf="!activeFilters.productId && kpi.KPISettingId === 4 && oae">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable && oee && oee.oee">{{(oee.oee * 100).toFixed(1)}}<span>%</span></h2>
                <h2 *ngIf="statsAvaiable && (!oee || !oee.oee)">{{'stops.not_available' | translate}}</h2>
                <button [disabled]="statsAvaiable && (!oee || !oee.oee)" (click)="navigate('oee')">Oae</button>
            </div>

            <!-- UPTIME IN % -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 10">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <span *ngIf="statsAvaiable" class="h2">{{uptimePerc + ' %'}}</span>
                <button [disabled]="true"
                    class="label-button">{{ 'realtime.production_time_perc' | translate }}</button>
            </div>

            <!-- DOWN TIME IN % -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 11">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <span *ngIf="statsAvaiable" class="h2">{{downtimePerc + ' %'}}</span>
                <button [disabled]="true" class="label-button">{{ 'realtime.downtime_perc' | translate }}</button>
            </div>

            <!-- UPTIME WITHOUT DOWNTIME -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 12">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <span *ngIf="statsAvaiable  && stats" class="h2"
                    [innerHTML]="uptimeNoProduction | durationPipe:'html'"></span>
                <button [disabled]="true" class="label-button">{{ 'realtime.production_time' | translate }}</button>
            </div>

            <!-- UPTIME WITHOUT DOWNTIME IN % -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 13">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <span *ngIf="statsAvaiable  && stats" class="h2">{{uptimeNoProductionPerc + ' %'}}</span>
                <button [disabled]="true"
                    class="label-button">{{ 'realtime.production_time_perc' | translate }}</button>
            </div>
            
            <!-- OPERATING TIME -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 14">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable && !operatingTimeEnabled">{{'stops.not_available' | translate}}</h2>
                <span *ngIf="statsAvaiable && operatingTimeEnabled" class="h2">{{operatingTime}}</span>
                <button [disabled]="true"
                    class="label-button operating-time-button">{{ 'realtime.operating_time' | translate }}</button>
            </div>

            <!-- OPERATING TIME PERCENTAGE -->
            <div class="data-item" *ngIf="kpi.KPISettingId === 15">
                <img src="assets/wait-data.gif" *ngIf="!statsAvaiable">
                <h2 *ngIf="statsAvaiable && !operatingTimeEnabled">{{'stops.not_available' | translate}}</h2>
                <span *ngIf="statsAvaiable && operatingTimeEnabled" class="h2">{{operatingTimePerc}}</span>
                <button [disabled]="true"
                    class="label-button operating-time-button">{{ 'realtime.operating_time_perc' | translate }}</button>
            </div>

        </div>
    </div>
</div>