<div class="task-dialog">
    <div class="title-container">
        <h3>Modifica Produzione</h3>
        <i class="fa fa-fw fa-times remove-description" (click)="cancel()"></i>
    </div>

    <div>
        <div class="session-container">
            <div class="field-container">
                <p class="section-label">Data inizio</p>
                <p class="section-label">Data fine</p>
            </div>
            <div class="field-container">
                <p class="p-data-text">{{tmpSession.beginAt | dateTimeFormat:'shortDate'}} {{tmpSession.beginAt | dateTimeFormat:'shortTime'}}</p>
                <p class="p-data-text">{{tmpSession.endAt | dateTimeFormat:'shortDate'}} {{tmpSession.endAt | dateTimeFormat:'shortTime'}}</p>
            </div>
            <p class="section-label">Prodotto</p>
            <p class="p-data-text">{{tmpSession.Product && tmpSession.Product.code ? tmpSession.Product.code : ''}}</p>
            <p class="p-data-description">{{tmpSession.Product && tmpSession.Product.name ? tmpSession.Product.name : ''}}</p>
            <div class="field-container">
                <div style="width: 100%;">
                    <p class="section-label">Pezzi</p>
                    <mat-form-field class="pieces-container">
                        <input matInput #pieces type="number" [(ngModel)]="tmpSession.itemsDeclared">
                    </mat-form-field>
                </div>
                <p class="p-data-text padding-bottom">Pezzi rilevati: {{tmpSession.itemsTotal}}</p>
            </div>
            <div class="field-container">
                <div style="width: 100%;">
                    <p class="section-label no-margin-top">Scarti</p>
                    <mat-form-field class="pieces-container">
                        <input matInput #scraps type="number" [(ngModel)]="tmpSession.scrapsDeclared">
                    </mat-form-field>
                </div>
                <p class="p-data-text padding-bottom">Scarti rilevati: {{tmpSession.scraps}}</p>
            </div>
            <div>
                <p class="section-label no-margin-top">Note</p>
                <mat-form-field class="instruction-container">
                    <textarea matInput type="text"
                        [(ngModel)]="tmpSession.note" class="text-area"></textarea>
                </mat-form-field>
            </div>

            <div class="footer-button">
                <div class="save-button" (click)="ok()" [ngClass]="{'disabled': loading || !isEdited()}">
                    <span>Save</span>
                </div>
            </div>
        </div>

    </div>
</div>