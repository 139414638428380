<div class="process-data-page">
    <app-filter-bar></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder">
        <div class="content" id="chartContainer">
            <div class="processdata-filter-bar">
                <div class="tcms-select">
                    <label>{{'pieces.process_data' | translate}}</label>
                    <mat-select  (selectionChange)="processDataChanged()" [(ngModel)]="selectedProcessData" panelClass="production_line">
                        <mat-option *ngFor="let pd of processDataList" value="{{pd.code}}">{{pd.description}}</mat-option>
                    </mat-select>
                </div>
                <div class="time-filter-container">
                    <div 
                        *ngFor="let granularity of granularities"
                        (click)="setGranularity(granularity)"
                        [ngClass]="{'active': activeGranularityMinutes === granularity.granularityMinutes, 'disabled': disabledGranularities.indexOf(granularity.granularityMinutes) !== -1}"
                        class="time-filter"
                    >{{ 'granularity.' + granularity.label | translate }}</div>
                </div>
            </div>
            <div class="chart-section values">
                <!-- <h2>{{ 'pieces.pieces' | translate }}</h2> -->
                <div id="chartdiv" [style.width.%]="100" [style.height.%]="100" [ngClass]="{'no-data': !availableData}"></div>
                <div [ngClass]="{'no-data': !availableData}" class="total"></div>
                <div class="no-chart" *ngIf="!lockRequest && !availableData">
                    <img src="{{getFavIconSrc()}}" width="120"/>
                    <p>{{'pieces.no_data1' | translate}} {{'pieces.process_data' | translate}} {{'pieces.no_data2' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>