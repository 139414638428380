import { CategoryStats } from "./category";
import { ErrorStats } from "./error";

export class Trend {
    slope: number;
    intercept: number;
}
export class OeeParams {
    availability: number;
    oee: number;
    performance: number;
    quality: number;
    idealSpeed?: number;
    speedLoss?: number;
    averageSpeed?: number;
    timeScheduled?: number;
    stopsStats?: StopsStats;
}
export class StopsStats {
    categories: CategoryStats[];
    errors: ErrorStats[];
}
export class Stats {
    scrapsPercentage: number;
    stops: number;
    piecesPerMin: number;
    uptime: number;
    downtime: number;
    mtbf: number;
    mttr: number;
    Trend: Trend;
    nTotal: number;
    piecesTotal: number;
    scrapsTotal: number;
}
