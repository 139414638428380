<div *ngIf="stops.length" class="table-container">
    <div class="stops-filter">
        <span class="filter-label">{{'filter_bar.filter_for' | translate}}:</span>
        <!-- STOP TYPE FILTER -->
        <mat-form-field>
            <mat-label>{{'stops.types' | translate}}</mat-label>
            <mat-select [(formControl)]="stopTypesSelected" multiple style="width: 200px;" panelClass="multiselect-panel">
                <mat-select-trigger>
                    {{stopTypesSelected.value?.length && stopTypesSelected.value.length < stopTypes.length ? stopTypesSelected.value[0].text : ''}}
                    {{(stopTypesSelected.value?.length && stopTypesSelected.value.length >= stopTypes.length ? 'stops.all_types' : '') | translate}}
                    <span *ngIf="stopTypesSelected.value?.length > 1 && stopTypesSelected.value?.length < stopTypes.length" class="multiselection">
                      (+{{stopTypesSelected.value.length - 1}} {{stopTypesSelected.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                <mat-option *ngFor="let stopType of stopTypes" [value]="stopType" class="multiselect-option">{{ stopType.text }}</mat-option>
             </mat-select>
        </mat-form-field>
        <!-- STOP GROUP FILTER -->
        <mat-form-field>
            <mat-label>{{'stops.groups' | translate}}</mat-label>
            <mat-select [(formControl)]="stopGroupsSelected" multiple style="width: 200px;" panelClass="multiselect-panel">
                <mat-select-trigger>
                    {{stopGroupsSelected.value?.length && stopGroupsSelected.value.length < stopGroups.length ? stopGroupsSelected.value[0].name : ''}}
                    {{(stopGroupsSelected.value?.length && stopGroupsSelected.value.length >= stopGroups.length ? 'stops.all_groups' : '') | translate}}
                    <span *ngIf="stopGroupsSelected.value?.length > 1 && stopGroupsSelected.value?.length < stopGroups.length" class="multiselection">
                      (+{{stopGroupsSelected.value.length - 1}} {{stopGroupsSelected.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                <mat-option *ngFor="let stopGroup of stopGroups" [value]="stopGroup" class="multiselect-option">{{ stopGroup.name }}</mat-option>
             </mat-select>
        </mat-form-field>
        <!-- STOP ERROR FILTER -->
        <mat-form-field>
            <mat-label>{{'stops.errors' | translate}}</mat-label>
            <mat-select [(formControl)]="stopErrorsSelected" multiple style="width: 200px;" panelClass="multiselect-panel">
                <mat-select-trigger>
                    {{stopErrorsSelected.value?.length && stopErrorsSelected.value.length < stopErrors.length ? stopErrorsSelected.value[0] : ''}}
                    {{(stopErrorsSelected.value?.length && stopErrorsSelected.value.length >= stopErrors.length ? 'stops.all_errors' : '') | translate}}
                    <span *ngIf="stopErrorsSelected.value?.length > 1 && stopErrorsSelected.value?.length < stopErrors.length" class="multiselection">
                      (+{{stopErrorsSelected.value.length - 1}} {{stopErrorsSelected.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                <mat-option *ngFor="let stopError of stopErrors" [value]="stopError" class="multiselect-option">{{ stopError }}</mat-option>
             </mat-select>
        </mat-form-field>
    </div>
    <div class="stops-table">
        <ngx-datatable #table
            class="fullscreen"
            [columns]="columns"
            [rows]="stops | stopTypePipe: stopTypesSelected.value | stopGroupPipe: stopGroupsSelected.value | stopErrorPipe: stopErrorsSelected.value"
            [headerHeight]="50"
            [rowHeight]="45"
            [columnMode]="'force'"
            [rowClass]="getRowClass"
        ></ngx-datatable>
    </div>
</div>

<!-- <button *ngIf="!(stops.length <= stopsLimit)" class="load-more-button" (click)="loadMoreStops()">{{ 'stops.load_more' | translate }}</button> -->

<ng-template #stopError let-value="value" let-row="row">
    <div class="error-container">
        <span class="{{row.errorTypeString}}" title="{{row.error && row.error.length ? row.error : row.label}}">
            {{row.error && row.error.length ? row.error : row.label}}
        </span>
    </div>
</ng-template>

<ng-template #errorsTag let-value="value" let-row="row">
    <span>{{value && value.name ? value.name : '-'}}</span>
</ng-template>

<ng-template #note let-value="value" let-row="row">
    <div class="note-container">
        <span title="{{row.note}}">{{value ? value : '-'}}</span>
    </div>
</ng-template>

<ng-template #product let-value="value" let-row="row">
    <span title="{{row.productCode}}">{{row.productCode}}</span>
</ng-template>

<ng-template #date let-value="value"  let-row="row">
    <div *ngIf="row.splitFrom" class="split-arrow-container">
        <span><i class="fa fa-reply fa-rotate-180" aria-hidden="true"></i></span>
    </div>
    <div class="date-container">
        <span class="date-text">{{value ?  (value | dateTimeFormat:'shortDate') : '-'}}</span>
        <span class="time-text">{{value | dateTimeFormat:'shortTime'}}</span>
    </div>
</ng-template>

<ng-template #duration let-value="value">
    <span>{{value | shortDurationPipe}}</span>
</ng-template>

<ng-template #action let-value="value" let-row="row">
    <div class="action-container" *ngIf="!row.splitFrom && canEditSplits() && row.errorTypeString != 'Change Activity' "> <!--shown if not supervisor-->
        <button mat-mini-fab class="action-button" (click)="setStopErrorModal(row)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>