<div class="loader-container" *ngIf="wait">
    <img src="{{getLoaderSrc()}}">
</div>

<div class="login-page">
        <video id="bgvid" playsinline autoplay muted loop>
            <source src="http://techmass.io/wp-content/uploads/2018/08/Hero-Video-Desktop.mp4" type="video/mp4">
        </video>
        <mat-card class="login-form">
            <mat-toolbar class="title">
                <span>{{'login.login' | translate}}</span>
            </mat-toolbar>
            <mat-card-content>
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                    <p>
                        <mat-form-field>
                            <input [(ngModel)]="mail" name="mail" formControlName="mail" type="email" matInput
                                placeholder="{{'login.email' | translate}}">
                            <mat-error *ngIf="!!loginForm.controls.mail.hasError('required')">
                                {{'login.emailError' | translate}}</mat-error>
                        </mat-form-field>
                    </p>
    
                    <p>
                        <mat-form-field>
                            <input [(ngModel)]="password" formControlName="password" name="password" matInput
                                placeholder="{{'login.password' | translate}}" type="password">
                            <mat-error *ngIf="!!loginForm.controls.password.hasError('required')">
                                {{'login.passwordError' | translate}}</mat-error>
                        </mat-form-field>
                    </p>
    
                    <button [disabled]="loginForm.pristine || loading" color="primary"
                        mat-button>{{'login.login' | translate}}</button>
                </form>
            </mat-card-content>
    
            <mat-card-footer>
                <span (click)="isPasswordLost(true)" class="forgot-password">{{'login.passwordLost' | translate}}</span>
            </mat-card-footer>
        </mat-card>
    
        <mat-card class="recover-form" *ngIf="passwordLost">
            <mat-toolbar class="title">
                <span>{{'login.recover.text' | translate}}</span>
            </mat-toolbar>
            <mat-card-content>
                <form [formGroup]="resetForm" (ngSubmit)="resetSubmit()">
                    <p>
                        <mat-form-field>
                            <input [(ngModel)]="recover_mail" name="recover_mail" formControlName="recover_mail" type="mail"
                                matInput placeholder="{{'login.email' | translate}}">
                            <mat-error *ngIf="!!resetForm.controls.recover_mail.hasError('required')">
                                {{'login.emailError' | translate}}</mat-error>
                        </mat-form-field>
                    </p>
                    <div class="dialog-actions">
                        <button [disabled]="resetForm.pristine || loading" color="primary" type="submit" class="reset"
                            mat-button>{{'login.recover.send' | translate}}</button>
                        <button (click)="isPasswordLost(false)" type="button"
                            mat-button>{{'login.recover.close' | translate}}</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
