<div class="mtbf-page">
    <app-filter-bar></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder">
        <div class="content" id="chartContainer">
            <div class="mtbf-filter-bar">
                <div class="tcms-select">
                    <label>{{'stops.error_type' | translate}}</label>
                    <select [(ngModel)]="selectedType" (change)="typeChanged()">
                        <option [value]="null">{{'stops.all_types' | translate}}</option>
                        <option [value]="0">{{'stops.unplanned' | translate}}</option>
                        <option [value]="1">{{'stops.planned' | translate}}</option>
                    </select>
                </div>
                <div class="tcms-select" *ngIf="selectedType === null" style="display: none;">
                    <select  (change)="errorChanged()" [(ngModel)]="selectedError" disabled>
                        <option [value]="null" selected>All reasons</option>
                    </select>
                </div>
                <div class="tcms-select" *ngIf="selectedType === 0">
                        <label>{{'stops.error' | translate}}</label>
                    <select  (change)="errorChanged()" [(ngModel)]="selectedError">
                        <option [value]="null" selected>{{'stops.all_errors' | translate}}</option>
                            <optgroup *ngFor="let tag of unplannedList" value="item" label="{{tag.name}}">
                                <option *ngFor="let error of tag.Errors" value="{{error.id}}">{{error.text}}</option>
                            </optgroup>
                    </select>
                </div>
                <div class="tcms-select" *ngIf="selectedType === 1">
                        <label>{{'stops.error' | translate}}</label>
                    <select  (change)="errorChanged()" [(ngModel)]="selectedError">
                        <option [value]="null" selected>{{'stops.all_errors' | translate}}</option>
                            <optgroup *ngFor="let tag of plannedList" value="item" label="{{tag.name}}">
                                <option *ngFor="let error of tag.Errors" value="{{error.id}}">{{error.text}}</option>
                            </optgroup>
                    </select>
                </div>
                <div class="time-filter-container">
                    <div 
                        *ngFor="let granularity of granularities"
                        (click)="setGranularity(granularity)"
                        [ngClass]="{'active': activeGranularityMinutes === granularity.granularityMinutes, 'disabled': disabledGranularities.indexOf(granularity.granularityMinutes) !== -1}"
                        class="time-filter">
                        {{ 'granularity.' + granularity.label | translate }}
                    </div>
                </div>
            </div>
            <div class="chart-section values">
                <div class="no-data" *ngIf="!lockRequest && !availableData">
                    <img src="{{getFavIconSrc()}}" width="120"/>
                    <p>{{'mtbf_mttr.no_data1' | translate}} <span *ngIf="selectedType != null">{{'mtbf_mttr.no_data2' | translate}}</span> {{'mtbf_mttr.no_data3' | translate}}</p>
                </div>
                <div id="chart-mtbf" [style.width.%]="100" [style.height.%]="100" *ngIf="availableData"></div>
            </div>
        </div>
    </div>
</div>
