<div class="oee-page">
    <app-filter-bar [multipleSelectEnabled]="true"></app-filter-bar>
    <div class="loader-container" *ngIf="lockRequest">
        <img src="{{getLoaderSrc()}}">
    </div>
    <div class="content-holder">
        <div class="content" id="chartContainer">
            <div class="chart-section">
                <div class="checkbox-container" *ngIf="params && params.oee && !oae">
                    <mat-checkbox class="checkbox-option" [checked]="considerPlanned" (change)="onCheckPlannedChange($event)">
                        {{ 'oee.include_planned' | translate }}
                    </mat-checkbox>
                    <mat-checkbox class="checkbox-option" [checked]="considerChangeOver" (change)="onCheckChangeOverChange($event)">
                        {{ 'oee.include_change_over' | translate }}
                    </mat-checkbox>
                </div>
                <div class="oee-shifts-number" *ngIf="params && params.oee && nShifts">
                    <span class="shifts-number-text">Shifts selected: </span><span class="shifts-number">{{nShifts}}</span>
                </div>
                <div class="oee" *ngIf="params && params.oee">
                    <div>
                        <span class="title" *ngIf="!oae">oee</span>
                        <span class="title" *ngIf="oae">oae</span>
                        <span *ngIf="params && (params.oee || params.oee === 0)"
                            class="value">{{params.oee.toFixed(1)}}<span class="percentage">%</span></span>
                        <span *ngIf="params && !params.oee && params.oee !== 0"
                            class="value">{{'oee.not_available' | translate}}</span>
                    </div>
                </div>
                <div class="oee" *ngIf="!params || !params.oee">
                    <div>
                       <span class="title" *ngIf="!oae">oee</span>
                        <span class="title" *ngIf="oae">oae</span>
                        <span class="value">{{'oee.not_available' | translate}}</span>
                    </div>
                </div>
                <div class="chart" [ngClass]="{'no-data': !lockRequest && !params.oee}">
                    <div id="chart-values" [style.width.%]="97" [style.height.%]="100"></div>
                </div>
                <div class="oee-values" *ngIf="params && params.oee">
                    <span class="value">{{params.quality.toFixed(1)}}<span class="percentage">%</span></span>
                    <span class="value">{{params.availability.toFixed(1)}}<span class="percentage">%</span></span>
                    <span class="value">{{params.performance.toFixed(1)}}<span class="percentage">%</span></span>
                </div>
                <!-- <button class="trend-button" (click)="navigate('oee/trends')">{{ 'oee.trends' | translate }} <i class="fa fa-angle-right"></i></button> -->
                <!-- style="font-size: 15px; width: 150px">{{'oee.performace_timerMode' | translate}} -->
                <div *ngIf="!activeFilters.isMultipleDeviceSelected()" class="section-for-single-oee">
                    <div class="loss-title" *ngIf="params && params.oee">
                    <span><i class="fa fa-area-chart"></i> {{'oee.performanceLoss' | translate}}</span>
                </div>
                    <div class="oee-details" [ngClass]="{'no-data': lockRequest}">
                    <div class="detail quality" >
                        <div id="qualityChart" [style.width.%]="100" [style.height.%]="100"></div>
                    </div>
                    <div class="detail availability" *ngIf="params">
                        <app-stops-bar *ngFor="let stop of stopBars.unplanned | limitTo: 5" [stop]="stop"
                            [isFrequencyChart]="false" [isPlanned]="false" [style.width.%]="100">
                        </app-stops-bar>
                    </div>
                    <div class="detail efficency" *ngIf="params && params.oee">
                        <div *ngIf="!timerMode" class="speed-container">
                            <div class="speed">
                                <span class="speed-text">{{'oee.averageTarget' | translate}}: </span><br /><span
                                    class="speed-number">{{params.idealSpeed.toFixed(1)}} </span><span
                                    class="percentage">{{'realtime.pieces_minute' | translate}}</span>
                            </div>
                            <div class="speed">
                                <span class="speed-text">{{'oee.productionAverage' | translate}}: </span><br /><span
                                    class="speed-number">{{params.averageSpeed.toFixed(1)}} </span><span
                                    class="percentage">{{'realtime.pieces_minute' | translate}}</span>
                            </div>
                        </div>
                        <div *ngIf="timerMode" class="speed-container">
                            <div class="speed">
                                <span class="speed-text">{{'oee.performance_timerMode' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="loss-title-container">
                    <div class="loss-shifts-number" *ngIf="params && params.oee && nShifts && considerSchedules">
                        <span class="shifts-number-text">{{'oee.shifts_selected' | translate}}</span><span class="shifts-number">{{nShifts}}</span>
                    </div>
                    <div class="loss-title" *ngIf="params && params.oee">
                        <span><i class="fa fa-signal fa-rotate-90"></i> {{'oee.performance' | translate}}</span>
                    </div>
                    <mat-slide-toggle class="slide-toggle" [checked]="considerSchedules" *ngIf="waterfall" (change)="onSlideSchedulesChange($event)">
                        {{ 'oee.include_schedules' | translate }}
                    </mat-slide-toggle>
                </div>
                    <div [style.width.%]="97" class="oee-graph">
                    <!-- <production-graph #productionGraph *ngFor="let item of graphCategories; index as index" [item]="item" [index]="index"></production-graph> -->
                    <div id="oeeWaterfall" [style.width.%]="100" [style.height.px]="500"></div>
                </div>
                    <div class="no-chart" *ngIf="!lockRequest && !params.oee">
                    <img src="{{getFavIconSrc()}}" width="120" />
                    <p>{{'pieces.no_data1' | translate}} {{'oee.schedules' | translate}}
                        {{'pieces.no_data2' | translate}}</p>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
