import { HttpService } from './http.service';
import { Response, ResponseContentType, Headers, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { QualityControlRawData } from 'app/models/quality-control';
import { LoginService } from './login.service';

import 'rxjs/add/operator/toPromise';
import * as moment from 'moment-timezone';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class QualityControlService {

  private taskHistoryDataBasePath = 'taskHistoryData';

  constructor(private http: HttpService,
              private _loginService: LoginService,
              private envSettings: EnvConfigurationService) { }

  async getAll(startDate: string, endDate: string, deviceId: number, producId?: number): Promise<QualityControlRawData[]> {
    let url;
    if (!startDate || !endDate) {
      url = `${this.envSettings.settings.apiBase}/${this.taskHistoryDataBasePath}`;
    } else {
      url = `${this.envSettings.settings.apiBase}/${this.taskHistoryDataBasePath}?deviceId=${deviceId}&fromDate=${startDate}&toDate=${endDate}`;
    }

    if (producId) {
      url = url + `&productId=${producId}`;
    }

    const response = await this.http
      .get(url)
      .toPromise();

    return response.json() as QualityControlRawData[];
  }

  /**
     * Get A download report link for the XLS
     *
     * @returns {string}
     *
     * @memberOf QualityControlService
     */
    
    getXLSLink(deviceId: number, dateFrom: Date, dateTo: Date, productId?: number): string {
      let url = `${this.envSettings.settings.excelExportBase}/v1/${this.taskHistoryDataBasePath}/xlsx?access_token=${this._loginService.token}&dateFrom=${dateFrom?dateFrom.toISOString(): null}&dateTo=${dateTo? dateTo.toISOString(): null}&deviceId=${deviceId}&timezone=${moment.tz.guess()}`;
      if (productId) {
          url += `&productId=${productId}`;
      }

      return url;
  }

  getReport(deviceId: number, dateFrom: Date, dateTo: Date, productId?: number): Promise<Response> {
    const url = this.getXLSLink(deviceId, dateFrom, dateTo, productId);

    let headers = new Headers();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    
    let options = new RequestOptions();
    options.responseType = ResponseContentType.Blob;
    options.headers = headers;
    
    return this.http.post(url, null, options).toPromise();
}
}