<div class="text-container">
    <div class="label-container">
        <span class="label">
            {{'stops.stop_time' | translate}}
        </span>
        <span class="label">
            {{'stops.total_time' | translate}}
        </span>
    </div>
    <div class="time-container">
        <span class="time" [ngClass]="{'no-data': !data}">
            <strong *ngIf="data">{{convertMillisToDays(data.stopsTime)}}</strong>{{'stops.days_shortened' | translate}} :
            <strong *ngIf="data">{{convertMillisToHours(data.stopsTime)}}</strong>{{'stops.hours_shortened' | translate}} :
            <strong *ngIf="data">{{convertMillisToMinutes(data.stopsTime)}}</strong>{{'stops.minutes_shortened' | translate}}
        </span>
        <span class="time" [ngClass]="{'no-data': !data}">
            <strong *ngIf="data">{{convertMillisToDays(data.totalTime)}}</strong>{{'stops.days_shortened' | translate}} :
            <strong *ngIf="data">{{convertMillisToHours(data.totalTime)}}</strong>{{'stops.hours_shortened' | translate}} :
            <strong *ngIf="data">{{convertMillisToMinutes(data.totalTime)}}</strong>{{'stops.minutes_shortened' | translate}}
        </span>
    </div>
</div>
<div class="bar-container">
    <div class="percentage" *ngIf="data">{{data.percentage.total}}%</div>
    <div class="bar">
        <tooltip #tooltip message="..."></tooltip>
        <div
            class="bar-item changeOver"
            [style.width.%]="data ? data.percentage.changeOver : 0"
            (mousemove)="tooltip.show($event, translate('stops.change_over') + ' - ' + convertMillisToText(data.totalTime * data.percentage.changeOver / 100))"
            (mouseout)="tooltip.hide($event)"
        ></div>
        <div
            class="bar-item planned"
            [style.width.%]="data ? data.percentage.planned : 0"
            (mousemove)="tooltip.show($event, translate('stops.planned') + ' - ' + convertMillisToText(data.totalTime * data.percentage.planned / 100))"
            (mouseout)="tooltip.hide($event)"
        ></div>
        <div
            class="bar-item unplanned"
            [style.width.%]="data ? data.percentage.unplanned : 0"
            (mousemove)="tooltip.show($event, translate('stops.unplanned') + ' - ' + convertMillisToText(data.totalTime * data.percentage.unplanned / 100))"
            (mouseout)="tooltip.hide($event)"
        ></div>
        <div
            class="bar-item notSpecified"
            [style.width.%]="data ? data.percentage.notSpecified : 0"
            (mousemove)="tooltip.show($event, translate('stops.not_specified') + ' - ' + convertMillisToText(data.totalTime * data.percentage.notSpecified / 100))"
            (mouseout)="tooltip.hide($event)"
        ></div>
        <div
            class="bar-item changeActivity"
            [style.width.%]="data ? data.percentage.changeActivity : 0"
            (mousemove)="tooltip.show($event, translate('stops.change_activity') + ' - ' + convertMillisToText(data.totalTime * data.percentage.changeActivity / 100))"
            (mouseout)="tooltip.hide($event)"
        ></div>
        <div
            class="bar-item nonProduction"
            [style.width.%]="data ? data.percentage.nonProduction : 0"
            (mousemove)="tooltip.show($event, translate('stops.not_production') + ' - ' + convertMillisToText(data.totalTime * data.percentage.nonProduction / 100))"
            (mouseout)="tooltip.hide($event)"
        ></div>
        <div
            class="bar-item"
            [style.width.%]="data ? data.percentage.other : 0"
            (mousemove)="tooltip.show($event, translate('stops.not_available') + ' - ' + convertMillisToText(data.totalTime * data.percentage.other / 100))"
            (mouseout)="tooltip.hide($event)"
        ></div>
    </div>
</div>