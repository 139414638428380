import { Device } from '../models/device';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class DevicesService {
    devices: Device[];
    constructor(private httpService: HttpService,
        private envSettings: EnvConfigurationService) { }

    /**
     * Method to get all devices
     *
     * @memberof DeviceService
     */
    async getDevices() {
        const url = `${this.envSettings.settings.apiBase}/device?populateCompany=true&populatePlant=true&includeModules=true`;
        const response = await this.httpService
            .get(url)
            .toPromise();

        this.devices = response.json() as Device[];

        // sort alphanumeric label
        this.devices.sort((a, b) => {
            var nameA = a.label.toUpperCase(); // ignore upper and lowercase
            var nameB = b.label.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
        });

        // sort companyId
        this.devices.sort((a, b) => {
            return a.companyId - b.companyId;
        })

        return this.devices;
    }

    /**
     * Method to get a device
     *
     * @memberof DeviceService
     */
    async getDevice(deviceId: number) {
        const url = `${this.envSettings.settings.apiBase}/device/${deviceId}`;
        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as Device;
    }

    /**
     * Method to get the device from the list with the given id
     *
     * @memberof DeviceService
     * @param {number} deviceId
     * @returns {Device}
     */
    getDeviceInfo(deviceId: number): Device {
        for (const device of this.devices) {
            if (device.id === deviceId) {
                return device;
            }
        }
        return null;
    }
}
