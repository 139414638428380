<div class="date-filter" 
    daterangepicker 
    [options]="options" 
    (selected)="selectedDate($event)"
    (applyDaterangepicker)="calendarApplied($event)">
<!-- <div class="date-filter" appClickOutside (clickOutside)="popupOpened = false"> -->
    <!-- <input type="text" name="daterangeInput" daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" /> -->
    <!-- <div daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
    </div> -->
    <div class="date-show">
        <label class="alternate-bold">{{ 'date_filter.start_date' | translate }}</label>
        <div class="date-shown">{{startDate | dateTimeFormat:'shortDate'}}</div>
        <div class="time-shown">{{startDate | dateTimeFormat:'shortTime'}}</div>
    </div>
    <div class="date-show">
        <label class="alternate-bold">{{ 'date_filter.end_date' | translate }}</label>
        <div class="date-shown">{{endDate | dateTimeFormat:'shortDate'}}</div>
        <div class="time-shown">{{endDate | dateTimeFormat:'shortTime'}}</div>
    </div>
    <div class="date-picker-opener">
            <!-- <div class="date-picker-opener" [ngClass]="{'not-shown': popupOpened}" (click)="activate()"> -->
        <i class="icon icon-period"></i>
    </div>
    <!-- <div class="date-picker-opener" [ngClass]="{'not-shown': !popupOpened}" (click)="ok()">
        <i class="icon icon-checkmark"></i>
    </div> -->
    <!-- <div class="date-popup" [ngClass]="{'active': popupOpened}">
        <div class="calendar">
            <p-calendar class="left" [(ngModel)]="startDate" [maxDate]="endDate" [inline]="true" (onSelect)="datePicked()">
            </p-calendar>
            <p-calendar class="right" [(ngModel)]="endDate" [minDate]="startDate" [inline]="true" (onSelect)="datePicked()">
            </p-calendar>
        </div>
        <div class="footer">
            <div class="footer-item" (click)="setDateInterval('today')"><p>{{ 'date_filter.today' | translate }}</p></div>
            <div class="footer-item" (click)="setDateInterval('yesterday')"><p>{{ 'date_filter.yesterday' | translate }}</p></div>
            <div class="footer-item" (click)="setDateInterval('lastweek')"><p>{{ 'date_filter.last_week' | translate }}</p></div>
            <div class="footer-item" (click)="setDateInterval('month')"><p>{{ 'date_filter.month_to_date' | translate }}</p></div>
            <div class="footer-item" (click)="setDateInterval('lastmonth')"><p>{{ 'date_filter.last_month' | translate }}</p></div>
        </div>
    </div> -->
</div>