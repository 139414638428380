/**
 * Interface for an order object
 */
export interface Order extends OrderInterface {
    /**ID of the database */
    _id?: any;
    id?: number;
    /**Code of the order*/
    code: string;
    /**Array of suborders */
    suborders?: Suborder[];
    /**Order is done */
    done?: boolean;
    /**Creation date of the order */
    createdAt: Date;
    /**Machine that does the order */
    deviceId: number;
}
export interface Suborder extends SuborderInterface {
    /**Code of the product */
    productCode: string;
    produced?: number;
}
/**
 * Interface for an order object
 */
export interface OrderInterface {
    /**Code of the order */
    code: string | number;
    /**Array of suborders */
    suborders?: SuborderInterface[];
    /**If the order is completed, meaning all suborders are completed(suborder could be missing notes) */
    orderProductionCompleted: boolean;
    /**Order is done, notes are set and checks are done */
    orderCompleted: boolean;
    /**When is the due date*/
    deliveryDate: Date;
}
/**
 * Interface for a suborder object
 */
export interface SuborderInterface {
    /**ID of the product, the one that is in the core */
    productId: number;
    /**Array of sessions */
    sessions: number[];
    /**Pieces to produce */
    target: number;
}

export interface OrderCore {
    id: number;
    code: string;
    items?: number;
    scraps?: number;
    status: OrderStatus
    target: number;
    Sessions?: OrderSession[];
    Devices?: OrderDevice[];
}

export enum OrderStatus {
    running = "running",
    suspended = "suspended",
    done = "done"
}

export interface OrderSession {
    id: number;
    beginAt: Date;
    endAt: Date;
    totalTime: number;
    changeWorkProcessTask: number;
    itemsPerMinute: number;
    notSpecified: number;
    planned: number;
    unplanned: number;
    scrapsPercentage: number;
}

export interface OrderDevice {
    id: number;
    items: number;
    scraps: number;
    label: string;
    Sessions: OrderSession[];
}

export interface OrderChartData {
    id: number;
    stroke: string;
    fill: string;
    code?: string;
    label?: string;
    items: number;
    scraps: number;
    target?: number;
    beginAt: Date;
    endAt?: Date;
    status?: OrderStatus;
    type: OrderChartDataType
}

export enum OrderChartDataType {
    session = 1,
    stop = 2
}

export interface OrderAnalytics {
    id: number;
    code?: string;
    label?: string;
    status?: OrderStatus;
    items: number;
    target: number;
    scraps: number;
    totalTime: number;
    stopTime: number;
}



