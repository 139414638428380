import { ActiveFiltersService } from './active-filters.service';
import { DevicesService } from './devices.service';
import { ThemeService } from './theme.service';
import { CompanyService } from './company.service';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { User, UserRole } from '../models/user';
import { CompanyInstance } from '../models/company';
import { EnvConfigurationService } from './env-config.service';

import { Injectable } from '@angular/core';



@Injectable()
export class LoginService {

    token: string;
    constructor(private router: Router,
        private httpService: HttpService,
        private _themeService: ThemeService,
        private _company: CompanyService,
        private envService: EnvConfigurationService) {
        this.loadState();
    }
    /**
     * Function to login a user
     *
     * @param {string} username
     * @param {string} password
     * @returns {string}
     *
     * @memberof LoginService
     */
    async login(email: string, password: string): Promise<boolean> {
        const url = `${this.envService.settings.apiBase}/login`;
        try {
            const response = await this.httpService
                .post(url, {
                    email: email,
                    password: password
                })
                .toPromise();
            const user = response.json() as User;
            this.writeInLocalStorage('user', email);
            this.writeInLocalStorage('role', "" + user.role);
            if (user.companyId !== null) {
                this.writeInLocalStorage('companyId', "" + user.companyId.toString());
            }

            // save name
            if (user.lastName || user.firstName) {
                localStorage.setItem('lastName', user.lastName ? user.lastName : '');
                localStorage.setItem('firstName', user.firstName ? user.firstName : '');
            }
            // save token in local storage
            this.setToken(user.token);

            this.httpService.addDefaultHeader('Authorization', `bearer ${this.token}`);

            return true;
        } catch (err) {
            if (err.status === 401 || err.status === 404) {
                // auth error
                return false;
            } else {
                // other error
                return false;
            }
        }
    }

    /**
     * Function to reset password
     *
     * @param {string} email
     * @returns {string}
     *
     * @memberof LoginService
     */
    async resetPassword(email: string, lang: string) {
        const url = `${this.envService.settings.apiBase}/forgotPassword`;
        const data = {
            email: email,
            lang: lang
        }

        const response = await this.httpService.post(url, data).toPromise();
        return response;
    }


    /**
     * Write data in local storage.
     *
     * @param {string} key
     * @param {string} data
     *
     * @memberof LoginService
     */
    writeInLocalStorage(key: string, data: string) {
        localStorage.setItem(key, data);
        return;
    }

    /**
     * Read data from local storage
     *
     * @param {string} key
     *
     * @memberof LoginService
     */
    readFromLocalStorage(key: string) {
        return localStorage.getItem(key);
    }

    clearState() {
        localStorage.clear();
        this.token = undefined;
    }

    /**
     * Set the access token
     *
     * @param {string} token
     *
     * @memberOf LoginService
     */
    setToken(token: string) {
        this.writeInLocalStorage('token', token);
        this.loadState();
    }

    /**
     * Load auth state from localstorage
     *
     *
     * @memberOf LoginService
     */
    loadState() {
        const token = this.readFromLocalStorage('token');
        const user = this.readFromLocalStorage('user');
        if (token) {
            // user already authenticated
            this.token = token;
            this.httpService.addDefaultHeader('Authorization', `bearer ${this.token}` );
        }
        this.checkProvider();

        // FIXME: the user should be taken in other ways rather than from /login (i.e. from a /user/me API call)
        // the following code has been disabled since it brokes the pdf report generator
        // if (!user) {
        //     if (token) {
        //         this.logout();
        //     }
        // }
    }

    async logout() {
        const url = `${this.envService.settings.apiBase}/logout`;
        try {
            const response = await this.httpService
                .put(url, {})
                .toPromise();
            if (response.status === 200) {
                this.clearState();
                this.router.navigateByUrl('/login');
            } else {
                return;
            }
        } catch (err) {
            if (err.status === 401 || err.status === 404) {
                // auth error
                return false;
            } else {
                // other error
                return false;
            }
        }
    }

    private async checkProvider() {

        const user = this.readFromLocalStorage('user');
        const role = parseInt(this.readFromLocalStorage('role'), 10);
        if (user && role !== UserRole.admin) {
            const company: CompanyInstance = await this._company.getCompany(parseInt(localStorage.getItem('companyId'), 10));
            if (company.provider) {
                this._themeService.setTeamSystemTheme(company.provider === 2);
            }
        }
        else if ( user && role === UserRole.admin) {
            this._themeService.setTeamSystemTheme(true);
        }
    }
}
