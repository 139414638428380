<div class="navbar" *ngIf="!isLoginPage()">
    <div class="side left">
        <i *ngIf="!isRootPage() && loginService.readFromLocalStorage('token')"
            style="cursor:pointer;" class="icon icon-left-arrow" (click)="goBack()"></i>
        <i *ngIf="isRootPage()" class="icon icon-menu" (click)="toggleMenu()"></i>
        <h2 class="title">{{navbar.title}}</h2>
    </div>
    <img class="logo" [src]="'./assets/images/' + getDirImages() + 'logo.png'">
    <div class="side right">
        <p *ngIf="loginService.readFromLocalStorage('lastName')">
            {{loginService.readFromLocalStorage('lastName') + ' ' + loginService.readFromLocalStorage('firstName')}}
        </p>
        <p *ngIf="loginService.readFromLocalStorage('user') && !loginService.readFromLocalStorage('lastName')">
            {{loginService.readFromLocalStorage('user')}}</p>
        <i class="icon icon-logout" *ngIf="loginService.readFromLocalStorage('token')" (click)="logout()"></i>
        <img class="lang-icon" src="/assets/lang/{{currentLang}}.svg" (click)="openMenu = !openMenu">
        <div class="lang-dropdown" [ngClass]="{ 'show': openMenu }">
            <ng-template ngFor let-lang [ngForOf]="languages">
                <div class="lang-button" (click)="setLanguage(lang.code)">
                    <img class="lang-icon" src="/assets/lang/{{lang.code}}.svg">
                </div>
            </ng-template>
        </div>
    </div>
    <div class="side-menu" [ngClass]="{'active' : showMenu}">
        <div class="content">
            <div class="left">
                <div class="menu-top">
                    <h2 class="title">MENU</h2>
                    <i class="icon icon-left-arrow" (click)="toggleMenu()"></i>
                </div>
                <nav class="menu-item" *ngIf="isOrderEnabled">
                    <div (click)="navigate('orders')" class="menu-button menu-option">
                        <i class="fa fa-list-alt" aria-hidden="true"></i>
                        {{ 'orders.title' | translate}}
                    </div>
                </nav>
                <nav class="menu-item" *ngIf="isQaControlsEnabled">
                    <div (click)="navigate('qa-dashboard')" class="menu-button menu-option">
                        <i class="fa fa-tasks" aria-hidden="true"></i>
                        {{ 'qa-dashboard.title' | translate}}
                    </div>
                </nav>
                <nav class="menu-item">
                    <div (click)="navigate('process-data')" class="menu-button menu-option">
                        <i class="fa fa-database" aria-hidden="true"></i>
                        {{ 'pieces.process_data' | translate}}
                    </div>
                </nav>
                <nav class="menu-item" *ngIf="isOperatorLoginEnabled">
                    <div (click)="downloadOperatorsLoginSessionsExcel()" class="menu-button menu-option">
                        <i class="fa fa-id-card-o" aria-hidden="true"></i>
                        {{ 'operators.title' | translate}}
                    </div>
                </nav>
                <nav class="menu-item">
                    <div (click)="navigate('settings')" class="menu-button menu-option">
                        <i class="fa fa-cog" aria-hidden="true"></i>
                        {{ 'setting.title' | translate}}
                    </div>
                </nav>
                <nav class="menu-item help-item" *ngIf="translate.currentLang === 'it'">
                    <a href="https://techmass.io/it/manuale-dashboard/" target="_blank" class="menu-button menu-option">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                        {{ 'setting.help' | translate}}
                    </a>
                </nav>
                <nav class="menu-item help-item" *ngIf="translate.currentLang !== 'it'">
                    <a href="https://techmass.io/en/dashboard-manual/" target="_blank" class="menu-button menu-option">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                        {{ 'setting.help' | translate}}
                    </a>
                </nav>
            </div>
            <div class="right" (click)="toggleMenu(true) && openMenu = false"></div>
        </div>
    </div>
</div>

<div class="navbar-login" *ngIf="isLoginPage()">
    <img class="logo" [src]="'./assets/images/' + getDirImages() + 'logo_login.png'">
    <div class="side left"></div>
    <div class="side right">
        <img class="lang-icon" src="/assets/lang/{{currentLang}}.svg" (click)="openMenu = !openMenu">
        <div class="lang-dropdown" [ngClass]="{ 'show': openMenu }">
            <ng-template ngFor let-lang [ngForOf]="languages">
                <div class="lang-button" (click)="setLanguage(lang.code)">
                    <img class="lang-icon" src="/assets/lang/{{lang.code}}.svg">
                </div>
            </ng-template>
        </div>
    </div>
</div>