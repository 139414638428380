import { Injectable } from "@angular/core";
import { EnvConfigurationService } from "./env-config.service";
import { HttpService } from "./http.service";
import { OrderGroups } from "../models/orderGroups";

@Injectable()
export class OrderGroupsDataService {
    constructor(
        private httpService: HttpService,
        private envSettings: EnvConfigurationService,
    )
    { }

    async getOrderGroupsList(startDate: string, endDate: string, deviceId: number): Promise<OrderGroups[]> {
        try {
            let url = `${this.envSettings.settings.apiBase}/groups?dateFrom=${startDate}&dateTo=${endDate}&deviceId=${deviceId}`;

            const response = await this.httpService
                .get(url)
                .toPromise();
            return response.json() as OrderGroups[];
        } catch(err) {
            console.error(err);
        }
    }
};
