import { Injectable } from '@angular/core';
import { StopType, Stop } from '../models/stop';
import { TranslateService } from '@ngx-translate/core';
import { ErrorType } from 'app/models/error';
import { StopTableRow, ErrorTypeString } from 'app/components/ui/stopsTable/stopTableRow.interface';
import * as moment from 'moment';
import { ActiveFiltersService } from './active-filters.service';

export enum StopErrorTypeLabels {
    PLANNED = 'Planned',
    UNPLANNED = 'Unplanned',
    CHANGE_OVER = 'Change Over',
    NOT_SPECIFIED = 'Not Specified',
    NON_PRODUCTION = 'Non Production',
    DISCONNECTION = 'Disconnection',
    CHANGE_WORK_PROCESS_TASK = "Change Activity"
}

@Injectable()
export class StopUtility {
    constructor(
        private _activeFilters: ActiveFiltersService,
        private _translate: TranslateService) { }

    getStopCauseLabelByStopType(stop) {
        switch (stop.stopType) {
            case StopType.changeOver:
                return this._translate.instant('realtime.change_over');
            case StopType.error:
                if (!stop.Error) {
                    return this._translate.instant('realtime.not_specified');
                } else {
                    if (stop.Error.Category.type === ErrorType.unplanned) {
                        return stop.Error.text;
                    } else {
                        return stop.Error.text;
                    }
                }
            case StopType.nonProduction:
                return this._translate.instant('realtime.non_production');
            case StopType.notSpecified:
                return this._translate.instant('realtime.not_specified');
            case StopType.disconnection:
                return this._translate.instant('realtime.disconnection');
            case StopType.changeWorkProcessTask:
                return this._translate.instant('realtime.change_activity');
            default:
                return '';
        }
    }

    getStopTypeLabelByCode(stopTypeCode: number):string {
        switch (stopTypeCode) {
            case 0:
                return this._translate.instant('stops.planned');
            case 1:
                return this._translate.instant('stops.unplanned');
            case 2:
                return this._translate.instant('stops.change_over');
            case 3:
                return this._translate.instant('stops.not_specified');
            case 4:
                return this._translate.instant('stops.not_production');
            case 5:
                return this._translate.instant('stops.change_activity');
        }
        return '';
    }

    getAllStopTypes() {
        let types = [];

        for (let x of [0,1,2,3,4,5]) {
            types.push({
                index: x,
                label: this.getStopTypeLabelByCode(x)
            });
        }

        return types;
    }

    getStopTypeTranslationByLabel(stopType: string):string {
        let key = this.getStopTypeTranslationKeyByLabel(stopType);
        return key && key != ''? this._translate.instant(key) : '';
    }


    getStopTypeTranslationKeyByLabel(stopType: string):string {
        switch (stopType) {
            case StopErrorTypeLabels.PLANNED:
                return 'stops.planned';
            case StopErrorTypeLabels.UNPLANNED:
                return 'stops.unplanned';
            case StopErrorTypeLabels.CHANGE_OVER:
                return 'stops.change_over';
            case StopErrorTypeLabels.NOT_SPECIFIED:
                return 'stops.not_specified';
            case StopErrorTypeLabels.NON_PRODUCTION:
                return 'stops.not_production';
            case StopErrorTypeLabels.CHANGE_WORK_PROCESS_TASK:
                return 'stops.change_activity';
        }
        return '';
    }

    getStopErrorTypeString(stop: Stop): ErrorTypeString {
        let errorTypeString: ErrorTypeString = null;

        if (stop.Error && stop.Error.Category && stop.type !== StopType.nonProduction) {
            errorTypeString = (stop.Error.Category.type === ErrorType.planned ? StopErrorTypeLabels.PLANNED : StopErrorTypeLabels.UNPLANNED);
        } else {
            switch (stop.type) {
                case StopType.changeOver:
                    errorTypeString = StopErrorTypeLabels.CHANGE_OVER;
                    break;
                case StopType.nonProduction:
                    errorTypeString = StopErrorTypeLabels.NON_PRODUCTION;                    
                    break;
                case StopType.notSpecified:
                    errorTypeString = StopErrorTypeLabels.NOT_SPECIFIED;       
                    break;
                case StopType.disconnection:
                    errorTypeString = StopErrorTypeLabels.DISCONNECTION;                    
                    break;
                case StopType.changeWorkProcessTask:
                    errorTypeString = StopErrorTypeLabels.CHANGE_WORK_PROCESS_TASK;                    
                    break;                 
                default: 
                    errorTypeString = null;
                    break;
            }
        }

        return errorTypeString;
    }

    getStopErrorTypeTranslationKey(stop: Stop): string {
        let label: string = null;

        if (stop.Error && stop.Error.Category && stop.type !== StopType.nonProduction) {
            label = stop.Error.Category.type === ErrorType.planned ? 'stops.planned' : 'stops.unplanned';
        } else {
            switch (stop.type) {
                case StopType.changeOver:
                    label = 'stops.change_over';
                    break;
                case StopType.notSpecified:
                    label = 'stops.not_specified';
                    break;
                case StopType.disconnection:
                    label = 'stops.disconnections';
                    break;
                case StopType.nonProduction:
                    label = 'stops.not_production';
                    break;
                case StopType.changeWorkProcessTask:
                    label = 'stops.change_activity';
                    break;
            }   
        }

        return label;
    }


    computeStopDuration(stopBegin, stopEnd, checkActiveFilters:boolean=false) {
        // Calculate the stop duration truncated to fit the time filters
        const duration = this.computeStopDurationMS(stopBegin, stopEnd, checkActiveFilters);
        
        return duration / 1000 / 60;
    }

    computeStopDurationMS(stopBegin, stopEnd, checkActiveFilters:boolean=false) {
        // Calculate the stop duration truncated to fit the time filters
        let filterStart = moment(stopBegin);
        let filterEnd = moment(stopEnd);
        
        if (checkActiveFilters) {
            if (moment(this._activeFilters.dateBegin).isAfter(moment(stopBegin))) {
                filterStart = moment(this._activeFilters.dateBegin);
            }
            if (!moment(stopEnd).isValid() || moment(this._activeFilters.dateEnd).isBefore(moment(stopEnd))) {
                filterEnd = moment(this._activeFilters.dateEnd);
            }
        }

        const duration = filterEnd.valueOf() - filterStart.valueOf();
        
        return duration;
    }

    computeStopStart(stopBegin) {
        // Calculate the stop duration truncated to fit the time filters
        let filterStart = moment(stopBegin);
        if (moment(this._activeFilters.dateBegin).isAfter(moment(stopBegin))) {
            filterStart = moment(this._activeFilters.dateBegin);
        }

        return filterStart;
    }

    computeStopEnd(stopEnd) {
        // Calculate the stop duration truncated to fit the time filters
        let filterEnd = moment(stopEnd);
        if (moment(this._activeFilters.dateEnd).isAfter(moment(stopEnd))) {
            filterEnd = moment(this._activeFilters.dateEnd);
        }

        return filterEnd;
    }

    transformStopTypeFromStop(stop: Stop): number {
        const offset = 2;
        let selectedStopType = null;
        if (stop.Error && stop.Error.Category) {
            selectedStopType = (stop.Error.Category.type === ErrorType.planned ? 1 : 0);
        } else {
            switch(stop.type) {
                case StopType.changeOver:
                    selectedStopType = StopType.changeOver + offset;
                    break;
                case StopType.nonProduction:
                    selectedStopType = StopType.nonProduction + offset;
                    break;
                case StopType.notSpecified:
                    selectedStopType = StopType.notSpecified + offset;
                    break;
                case StopType.changeWorkProcessTask:
                    selectedStopType = StopType.changeWorkProcessTask + offset;
                    break;
            }
        }

        return selectedStopType;
    }
    transformStopTypeFromStopTableRow(stop: StopTableRow): number {
        const offset = 2;

        let selectedStopType = stop.selectedStopType;

        if (stop.selectedErrorId) {
            selectedStopType = StopType.error;
        } else {
            switch(stop.selectedStopType) {
                case StopType.changeOver+offset:
                    selectedStopType = StopType.changeOver;
                    break;
                case StopType.nonProduction+offset:
                    selectedStopType = StopType.nonProduction;
                    break;
                case StopType.notSpecified+offset:
                    selectedStopType = StopType.notSpecified;
                    break;
                case StopType.changeWorkProcessTask+offset:
                    selectedStopType = StopType.changeWorkProcessTask;
                    break;
            }
        }

        return selectedStopType;
    }
    

    transformStop(stop: Stop, checkActiveFilters: boolean = false): StopTableRow {
        const filterDuration = this.computeStopDuration(stop.beginAt, stop.endAt, checkActiveFilters);
        const filterStart = this.computeStopStart(stop.beginAt);
        //const filterEnd = this.computeStopEnd(stop.endAt);
   
        let errorTypeString: ErrorTypeString;
        let label: string;
        let selectedStopType: number;
        // Get human-readable stop strings
        this._translate.stream([
            'stops.change_over',
            'stops.planned',
            'stops.unplanned',
            'stops.not_specified',
            'stops.not_production',
            'stops.change_activity',
            'stops.disconnections',
        ]).subscribe((translations) => {
            
            label = translations[this.getStopErrorTypeTranslationKey(stop)];
        });

        selectedStopType = this.transformStopTypeFromStop(stop);
        errorTypeString = this.getStopErrorTypeString(stop);
        
        // Return stop in new format
        return {
            date: filterStart.toDate(),
            endDate: stop.endAt,
            duration: filterDuration,
            filterDuration: filterDuration,
            errorTypeString: errorTypeString,
            selectedStopType: selectedStopType,
            selectedErrorId: stop.errorId,
            stopType: stop.type,
            type: stop.type,
            error: stop.Error ? stop.Error.text : '',
            errorsTag: (stop.Error && stop.Error.ErrorsTags && stop.Error.ErrorsTags.length > 0) ? stop.Error.ErrorsTags[0] : null,
            productCode: stop.Product ? stop.Product.code : '-',
            productId: stop.productId,
            note: stop.note,
            id: stop.id,
            deviceId: stop.deviceId,
            label: label,
            beginAt: new Date(stop.beginAt),
            endAt: stop.endAt ? new Date(stop.endAt) : null,
            hasSplits: stop.isSplit,
            splitFrom: stop.splitRef
        };

    }
}