import { DateAdapter, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Error } from 'tslint/lib/error';
import { StopType, Stop } from 'app/models/stop';
import { ErrorModel, ErrorType } from '../../../models/error';
import { ErrorTypeString, StopTableRow } from '../stopsTable/stopTableRow.interface';
import { StopUtility } from '../../../services/stop.utility';
import { ErrorsService } from '../../../services/error.service';
import { StopService } from '../../../services/stop-history.service';
import { ActiveFiltersService } from 'app/services/active-filters.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LoaderService } from 'app/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from 'app/services/ui.service';

export interface ErrorModelForSelect extends ErrorModel {
    selectLabel: string;
    errorTypeString: ErrorTypeString;
}

const STOP_TYPE = {
    NON_PRODUCTION: 4,
    CHANGE_OVER: 2,
    UNPLANNED: 0,
    PLANNED: 1,
    NOT_SPECIFIED: 5
};

@Component({
    styleUrls: ['./dialog-set-stop-error.component.scss'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './dialog-set-stop-error.component.html'
})
export class SetStopErrorDialogComponent implements OnInit {
    errorId: number;
    errors: ErrorModelForSelect[];
    errorsSelectList = [];
    loading = false;
    
    plannedList;
    unplannedList;

    stop: StopTableRow;
    splits: StopTableRow[];
    
    formValid = false;
    stopTypes = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SetStopErrorDialogComponent>,
        private _errorsService: ErrorsService,
        private _stopService: StopService,
        private _activeFilterService: ActiveFiltersService,
        private _stopUtility: StopUtility,
        private _translateService: TranslateService,
        private _ui: UiService,
        private dateAdapter: DateAdapter<Date>
    ) { 
        this.stop = data.stop;
        this.splits = data.splits.reverse();
        const lang = localStorage.getItem("lang");
        if(lang)
            this.dateAdapter.setLocale(lang);
        else 
            this.dateAdapter.setLocale("en");
    }

    async ngOnInit() {
        if (this.stop.type === StopType.error || this.stop.type === StopType.notSpecified) {
            await this.buildErrorsGroups();
        }
        
        this.stopTypes = this._stopUtility.getAllStopTypes();
        this.errorsSelectList = await this._errorsService.getErrors(this._activeFilterService.deviceId);
        this.errorsSelectList.sort(this.sortByText);

        this.checkSplitsValid();
    }


    private _transformErrorsToErrorsForSelect(errors: ErrorModel[]) {
        return errors.map((error: ErrorModelForSelect) => {
            const errorTypeString: ErrorTypeString = (error.Category && error.Category.type === ErrorType.planned) ? 'Planned' : 'Unplanned';
            error.selectLabel = error.text;
            error.errorTypeString = errorTypeString;
            return error;
        });
    }

    checkSplitsValid() {
        let isValid = true;

        if (this.splits.length == 0) {
            isValid = isValid && this.stop.selectedStopType != null && this.stop.selectedStopType != undefined;
            isValid = isValid && 
                        !(
                            (this.stop.selectedStopType == STOP_TYPE.UNPLANNED || this.stop.selectedStopType == STOP_TYPE.PLANNED) && 
                            (this.stop.selectedErrorId == null || this.stop.selectedErrorId == undefined)
                        );
        } else if (this.splits.length == 1) {
            //Need at least 2 splits
            isValid = false;
        } else {

            if (this.stop.selectedStopType == STOP_TYPE.CHANGE_OVER) {
                isValid = isValid && this.splits.filter(el => (el.selectedStopType == STOP_TYPE.CHANGE_OVER)).length > 0;
                if (!isValid) {
                    this._ui.openSnackBar(this._translateService.instant('dialog_stop.changeover_atleastone'));
                }
            } else if (this.stop.selectedStopType == STOP_TYPE.NON_PRODUCTION) { 
                isValid = isValid && this.splits.filter(el => (el.selectedStopType == STOP_TYPE.NON_PRODUCTION)).length > 0;
                if (!isValid) {
                    this._ui.openSnackBar(this._translateService.instant('dialog_stop.nonproduction_atleastone'));
                }
            }

            //check that error has been selected
            isValid = isValid && this.splits.filter(el => (el.selectedStopType == STOP_TYPE.UNPLANNED || el.selectedStopType == STOP_TYPE.PLANNED) && (el.selectedErrorId == null || el.selectedErrorId == undefined)).length <= 0;
            isValid = isValid && this.splits.filter(el => (el.selectedStopType == null || el.selectedStopType == undefined)).length <= 0;
            
            //check begin/end dates
            let begin = this.stop.beginAt;
            let end = this.stop.endAt;
            for( let aSplit of this.splits) {
                //Check that each split is between stop begin/end
                if (aSplit.beginAt.getTime() < begin.getTime() || aSplit.endAt.getTime() > end.getTime()) {
                    isValid =  isValid && false;
                    break;  
                }

                //Check that begin of a split is before end of that split
                if (begin.getTime() > end.getTime()) {
                    isValid =  isValid && false;
                    break;  
                }
            }
        }
        
        this.formValid = isValid;
    }

    async buildErrorsGroups() {
        this.plannedList = [];
        this.unplannedList = [];
        
        let errorsList = await this._errorsService.getErrors(this._activeFilterService.deviceId);
        let errorsIds = new Set(errorsList.map( e => e.id));
        
        let errorsTagList = await this._errorsService.getTagsWithErrors(this._activeFilterService.device.companyId);
        errorsTagList = errorsTagList.filter((errorTag) => {
            return errorTag.Errors.some( e => errorsIds.has(e.id))
        });
        
        for (const tag of errorsTagList) {
            const planned = {
                id: tag.id,
                name: tag.name ? tag.name : 'Other',
                Errors: []
            };
            const unplanned = {
                id: tag.id,
                name: tag.name ? tag.name : 'Other',
                Errors: []
            };
            
            for (const error of tag.Errors) {
                if (errorsIds.has(error.id)) {
                    if (error.type === STOP_TYPE.UNPLANNED) {
                        unplanned.Errors.push(error);
                    }
                    if (error.type === STOP_TYPE.PLANNED) {
                        planned.Errors.push(error);
                    }
                }
            }

            if (planned.Errors.length > 0) {
                planned.Errors = this._transformErrorsToErrorsForSelect(planned.Errors);
                this.plannedList.push(planned);
            }
            if (unplanned.Errors.length > 0) {
                unplanned.Errors = this._transformErrorsToErrorsForSelect(unplanned.Errors);
                this.unplannedList.push(unplanned);
            }

        }

        this.plannedList.sort(this.sortByName);
        this.unplannedList.sort(this.sortByName);
    }

    private sortByName(a,b) {
        if (a.name && b.name && a.name.toUpperCase() >= b.name.toUpperCase()) {
            return 1;
        } else {
            return -1;
        }
    }
    private sortByText(a,b) {
        if (a.text && b.text && a.text.toUpperCase() >= b.text.toUpperCase()) {
            return 1;
        } else {
            return -1;
        }
    }


    /**
     * Set the error and close the modal
     *
     * @memberOf SetStopErrorDialogComponent
     */
    async ok() {
        if (!this.errorId) {
            throw new Error('errorId not set');
        }

        this.loading = true;
        await this._stopService.setError(this.stop.id, this.errorId);
        this.loading = false;

        this.dialogRef.close(true);
    }

    async saveSplits() {
        try {
            this.loading = true;
            let transformedSplits: Stop[] = this.splits.map( el => {
                return {                    
                    duration: el.duration,
                    type: this._stopUtility.transformStopTypeFromStopTableRow(el),
                    errorId: el.selectedErrorId,
                    beginAt: el.beginAt,
                    endAt: el.endAt,
                    deviceId: el.deviceId || this.stop.deviceId,
                    productId: el.productId ||  this.stop.productId,
                    note: el.note
                }
            });
            let transformedStop: Stop = {
                id: this.stop.id,
                type: this._stopUtility.transformStopTypeFromStopTableRow(this.stop),
                errorId: this.stop.selectedErrorId,
                note: this.stop.note,
                beginAt: this.stop.beginAt,
                endAt: this.stop.endAt,
                duration: this.stop.duration,
                productId: this.stop.productId,
                deviceId: this.stop.deviceId
            };
            
            let res1 = await this._stopService.updateStop(transformedStop);
            if (!res1) {
                this._translateService.get('dialog_stop.save_error',{details: ''}).subscribe((res) => {
                    this._ui.openSnackBar(res);
                });
            } else {            
                let res2 = await this._stopService.updateSplits(this.stop.id, transformedSplits);

                if (res2) {
                    this.dialogRef.close(true);
                    this._translateService.get('dialog_stop.save_success').subscribe((res) => {
                        this._ui.openSnackBar(res);
                    });
                }
            }
        } catch (err) {
            this._translateService.get('dialog_stop.save_error',{details: ''}).subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        } finally {
          this.loading = false;
        }
        
    }
    /**
     * Close the modal
     * 
     * @memberOf SetStopErrorDialogComponent
     */
    cancel() {
        this.dialogRef.close();
    }

    typeChanged($event, split) {
        split.selectedStopType = $event.value;
        split.type = $event.value;
        split.selectedErrorId = null;
        this.checkSplitsValid();
    }

    stopReasonChanged($event, split) {
        split.selectedErrorId = $event.value;
        this.checkSplitsValid();
    }

    addSplit() {
        if (this.splits.length <= 0) {
            //First time --> add 2 splits!
            let firstSplit: StopTableRow = Object.assign({}, this.stop, { splitFrom: this.stop.id});
            this.splits = [];
            this.splits.push(firstSplit); 
            delete firstSplit.id;       
        } 

        let newSplit: StopTableRow = Object.assign({}, this.stop, { splitFrom: this.stop.id});
        delete newSplit.id;
        this.splits.push(newSplit);
        
        this.adjustSplitsDates();
        this.checkSplitsValid();
    }

    beginAtEditable(splitIndex) {
        return splitIndex > 0;
    }

    endAtEditable(splitIndex) {
        return splitIndex < this.splits.length-1;
    }

    isMoreDays(from: Date, to: Date) {
        return to.getDate() - from.getDate() > 0;
    }

    removeSplit(toRemove, index) {
        this.splits.splice(index,1);

        this.adjustSplitsDates();
        this.checkSplitsValid();
    }

    getStopCauseLabelByStopType(stop) {
        return stop.error;
        // return this._stopUtility.getStopCauseLabelByStopType(stop);
    }

    getStopTypeLabel(stop) {
        return this._stopUtility.getStopTypeLabelByCode(stop.selectedStopType);
    }

    checkBeginSplitDate($event, split, index) {
        let newBegin: Date = new Date($event.value);

        if (newBegin < this.stop.beginAt) {
            newBegin = moment(this.stop.beginAt).toDate();

            this._translateService.get('dialog_stop.begin_before_stopbegin').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
        if (newBegin > this.stop.endAt) {
            newBegin = moment(this.stop.endAt).add(-1, 's').toDate();

            this._translateService.get('dialog_stop.begin_after_stopend').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
        if (newBegin > split.endAt) {
            newBegin = moment(split.endAt).add(-1, 's').toDate();

            this._translateService.get('dialog_stop.begin_after_end').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        split.beginAt = newBegin;

        // Cerca buchi tra lo split corrente e quello successivo
        const previousSplitIdx = index - 1;
        if (previousSplitIdx >= 0) {
            let previousSplit = this.splits[previousSplitIdx];
            
            //Change next split begin date
            previousSplit.endAt =  moment(split.beginAt).toDate();
        }

        this.updateSplitDuration(split);
        this.checkSplitsValid();
    }

    checkEndSplitDate($event, split, index) {
        let newEnd: Date = new Date($event.value);
    
        if (newEnd < split.beginAt) {
            newEnd = moment(split.beginAt).add(1, 's').toDate();

            this._translateService.get('dialog_stop.end_before_begin').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
        if (newEnd < this.stop.beginAt) {
            newEnd = moment(this.stop.beginAt).add(1, 's').toDate();

            this._translateService.get('dialog_stop.end_before_stopbegin').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        if (newEnd > this.stop.endAt) {
            newEnd = moment(this.stop.endAt).toDate();

            this._translateService.get('dialog_stop.end_after_stopend').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        split.endAt = newEnd;

        // Cerca buchi tra lo split corrente e quello successivo
        const nextSplitIdx = index + 1;
        if (this.splits.length > nextSplitIdx) {
            let nextSplit = this.splits[nextSplitIdx];
            
            //Change next split begin date
            nextSplit.beginAt =  moment(split.endAt).toDate();
        }

        this.updateSplitDuration(split);
        this.checkSplitsValid();
    }

    setBeginSplitTime($event, split, index) {
        let newBegin = this.addTimeToDate(split.beginAt, $event);

        if (newBegin.getTime() < this.stop.beginAt.getTime()) {
            newBegin = moment(this.stop.beginAt).toDate();

            this._translateService.get('dialog_stop.begin_before_stopbegin').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
        if (newBegin.getTime() > this.stop.endAt.getTime()) {
            newBegin = moment(this.stop.endAt).add(-1, 's').toDate();

            this._translateService.get('dialog_stop.begin_after_stopend').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
        if (newBegin.getTime() > split.endAt.getTime()) {
            newBegin = moment(split.endAt).add(-1, 's').toDate();

            this._translateService.get('dialog_stop.begin_after_end').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
        
        split.beginAt = newBegin;

        // Cerca buchi tra lo split corrente e quello successivo
        const previousSplitIdx = index - 1;
        if (previousSplitIdx >= 0) {
            let previousSplit = this.splits[previousSplitIdx];
            
            //Change next split begin date
            previousSplit.endAt =  moment(split.beginAt).toDate();
        }

        this.updateSplitDuration(split);
        this.checkSplitsValid();
    }
    
    setEndSplitTime($event, split, index) {
        let newEnd = this.addTimeToDate(split.endAt, $event);

        if (newEnd.getTime() < split.beginAt.getTime()) {
            newEnd = moment(split.beginAt).add(1, 's').toDate();

            this._translateService.get('dialog_stop.end_before_begin').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }
        if (newEnd.getTime() < this.stop.beginAt.getTime()) {
            newEnd = moment(this.stop.beginAt).add(1, 's').toDate();

            this._translateService.get('dialog_stop.end_before_stopbegin').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        if (newEnd.getTime() > this.stop.endAt.getTime()) {
            newEnd = this.stop.endAt;

            this._translateService.get('dialog_stop.end_after_stopend').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        split.endAt = newEnd;

        // Cerca buchi tra lo split corrente e quello successivo
        const nextSplitIdx = index + 1;
        if (this.splits.length > nextSplitIdx) {
            let nextSplit = this.splits[nextSplitIdx];
            
            //Change next split begin date
            nextSplit.beginAt =  moment(split.endAt).toDate();
        }

        this.updateSplitDuration(split);
        this.checkSplitsValid();
    }

    disableStopReason(split) {
        return split.selectedStopType != 0 && split.selectedStopType != 1;
    }

    /* Non sarà possibile modificare uno stop 
        pianificato/non pianificato/non specificato con 
        un change over/non produzione e viceversa.
        Sarà invece possibile un cambiamento all’interno di questi due gruppi di tipi di stop e/o
        dividere lo stop in due o più parti, cliccando sul bottone “split stop”, che sarà sempre
        visibile in fondo al popup.

         Transizioni di stato possibili:
             - Da "Pianificato" a "Pianificato"/"Non Pianificato"
             - Da "Non Pianificato" a "Pianificato"/"Non Pianificato"
             - Da "Non Specificato" a "Pianificato"/"Non Pianificato"/"Non Specificato"
             
             - Da "Change Over" a :
                - "Non Produzione"
                - "Pianificato"/"Non Pianificato"/"Non Specificato" ammesso che almeno un altro split sia "Change Over" 
             - Da "Non Produzione" a:
                - "Change Over"
                - "Pianificato"/"Non Pianificato"/"Non Specificato" ammesso che almeno un altro split sia "Change Over" 
    */
    stopTypeEnabled(stopType, isParent) {
        switch(this.stop.stopType) {
            case StopType.error: 
                return stopType == STOP_TYPE.UNPLANNED || stopType == STOP_TYPE.PLANNED;
            case StopType.notSpecified:
                return stopType == STOP_TYPE.UNPLANNED || stopType == STOP_TYPE.PLANNED || stopType == STOP_TYPE.NOT_SPECIFIED;
            case StopType.changeOver:
            case StopType.nonProduction:
                if(typeof isParent != 'undefined' && isParent) {
                    return stopType == STOP_TYPE.NON_PRODUCTION || stopType == STOP_TYPE.CHANGE_OVER; 
                } else {
                    return stopType == STOP_TYPE.NON_PRODUCTION || stopType == STOP_TYPE.CHANGE_OVER || 
                        stopType == STOP_TYPE.UNPLANNED || stopType == STOP_TYPE.PLANNED || stopType == STOP_TYPE.NOT_SPECIFIED;
                }
            default:
                return false;
        }

        // let is_stop_changeover_or_not_production = this.stop.stopType == StopType.changeOver || this.stop.stopType == StopType.nonProduction;

        // if (is_stop_changeover_or_not_production) {
        //     if (stopType == 2 || stopType == 4) 
        //         return true;

        //     return false;
        // } else {
        //     if (stopType == 0 || stopType == 1 || stopType == 5) 
        //         return true;

        //     return false;
        // }
    }

    stopReasonRequired(stopOrSplit) {
        return stopOrSplit && (stopOrSplit.selectedStopType == STOP_TYPE.UNPLANNED || stopOrSplit.selectedStopType == STOP_TYPE.PLANNED);
    }

    private addTimeToDate(originalDate, time) {
        let date = moment(originalDate).format('MM-DD-YYYY');
        let timeAndDate = moment(date + ' ' + time);

        return timeAndDate.toDate();
    }

    private adjustSplitsDates() {
        let newDateRanges = this.computeSplitsBeginEnd(this.splits.length);

        this.splits.forEach( (e,i) => {            
            e.beginAt = newDateRanges[i].beginAt;
            e.date = newDateRanges[i].beginAt;
            e.endAt = newDateRanges[i].endAt;

            this.updateSplitDuration(e);
        });
    }

    private updateSplitDuration(split) {
        let filterDuration = this._stopUtility.computeStopDurationMS(split.beginAt, split.endAt);
        split.duration = filterDuration;
        split.filterDuration = filterDuration;
    }

    private computeSplitsDuration(numberOfSplits) {
        let duration = this._stopUtility.computeStopDurationMS(this.stop.beginAt, this.stop.endAt);
        return duration / numberOfSplits; 
    }

    private computeSplitsBeginEnd(numberOfSplits) {
        const singleSplitDuration = this.computeSplitsDuration(numberOfSplits);
        let begin = this.stop.beginAt;
        let currentBegin = begin;
        let currentEnd = currentBegin;
        let dateRange = [];

        for (let i = 0; i < numberOfSplits; i++) {
            currentEnd = moment(currentBegin).add(singleSplitDuration, 'ms').toDate();
            dateRange.push({
                beginAt: currentBegin,
                endAt: currentEnd
            });
                        
            currentBegin = currentEnd;
        }

        return dateRange;
    }
}
