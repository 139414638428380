<div class="header">
    <div class="back" (click)="backToMain()">
        < {{'stops.back' | translate}} </div>
            <h1 [ngClass]="{'no-data' : title !== 'planned'}">
                {{'stops.planned_stop' | translate }}
            </h1>
            <h1 [ngClass]="{'no-data' : title !== 'unplanned'}">
                {{'stops.unplanned_stop' | translate }}
            </h1>
            <h1 [ngClass]="{'no-data' : title === 'planned' || title === 'unplanned'}">
                {{title}}
            </h1>
            <span class="separator"></span>
    </div>
    <div class="charts">
        <div class="chart-container">
            <div class="slide-container">
                <span class="mat-slide-toggle-content">{{'stops.duration' | translate}}</span>
                <mat-slide-toggle (change)="changeDonutToggle($event)" [style.height.px]="28"></mat-slide-toggle>
                <span class="mat-slide-toggle-content">{{'stops.frequency' | translate}}</span>
            </div>
            <div #donutChart [style.width.%]="100" [style.height.%]="90"></div>
        </div>
        <!-- <form *ngIf="XLSlink" target="_blank" ngNoForm method="post" action="{{XLSlink}}" class="export-report">
        <button type="submit">
            <i class="icon-download-report"></i>
        </button>
    </form> -->
        <!-- <form *ngIf="downloadReportLink" target="_blank" ngNoForm method="post" action="{{downloadReportLink}}" class="export-report">
        <button type="submit">
            <i class="icon-download-report"></i>
        </button>
    </form> -->
        <div class="bars-container">
            <div class="chart" *ngIf="plannedData">
                <div class="title-container">
                    <div *ngIf="hasStopGroups" class="section-button" (click)="openPlannedTab()"></div>
                    <h1 class="title">{{'stops.planned_stop' | translate}}</h1>
                </div>
                <div class="tcms-select">
                    <label>{{'stops.errorsTag' | translate}}</label>
                    <select [(ngModel)]="filterStopBar.planned" (change)="reRenderStopData()">
                        <option *ngFor="let type of plannedTypeList" [value]="type">{{type}}</option>
                    </select>
                </div>
                <app-stops-bar #plannedBars *ngFor="let stop of plannedData | limitTo: plannedLimit" [stop]="stop"
                    [isFrequencyChart]="isFrequencyChart" [isPlanned]="true" [style.width.%]="100">
                </app-stops-bar>
                <div *ngIf="plannedData.length > 7" >
                    <button *ngIf="plannedLimit === 7" (click)="changeUnplannedLimit()" mat-button>
                        {{'stops.show-more' | translate}}
                    </button>
                    <button *ngIf="plannedLimit === 100" (click)="changeUnplannedLimit()" mat-button>
                        {{'stops.show-less' | translate}}
                    </button>
                </div>
            </div>
            <div class="chart" *ngIf="unplannedData">
                <div class="title-container">
                    <div *ngIf="hasStopGroups" class="section-button" (click)="openUnplannedTab()"></div>
                    <h1 class="title">{{'stops.unplanned_stop' | translate}}</h1>
                </div>
                <div class="tcms-select">
                    <label>{{'stops.errorsTag' | translate}}</label>
                    <select [(ngModel)]="filterStopBar.unplanned" (change)="reRenderStopData()">
                        <option *ngFor="let type of unplannedTypeList" [value]="type">{{type}}</option>
                    </select>
                </div>
                <app-stops-bar #unplannedBars *ngFor="let stop of unplannedData | limitTo: unplannedLimit" [stop]="stop"
                    [isFrequencyChart]="isFrequencyChart" [isPlanned]="false" [style.width.%]="100">
                </app-stops-bar>
                <div *ngIf="unplannedData.length > 7" class="button-container">
                    <button *ngIf="unplannedLimit === 7" (click)="changeUnplannedLimit()" mat-button>
                        {{'stops.show-more' | translate}}
                    </button>
                    <button *ngIf="unplannedLimit === 100" (click)="changeUnplannedLimit()" mat-button>
                        {{'stops.show-less' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>